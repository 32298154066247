import React, { useEffect, useState } from 'react'
import { MeterChart, TableBox } from './component/Charts'
import { useLocation, useNavigate } from 'react-router-dom'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import axios from 'axios'

import { FaRegLightbulb } from 'react-icons/fa'
import { IoCartOutline } from 'react-icons/io5'
import { IoCarSportOutline } from 'react-icons/io5'
import { IoCalendarOutline } from 'react-icons/io5'
import { IoTriangle } from 'react-icons/io5'
import ApexColumnChart from './component/Charts/ApexColumnChart'
import ApexGaugeChart from './component/Charts/ApexGaugeChart'

const Statistics = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const axiosPrivate = useAxiosPrivate()

  const [data, setData] = useState([])
  const [year, setYear] = useState('')
  const [site, setSite] = useState('')
  const yearsOption = Array.from({ length: 100 }, (_, index) => new Date().getFullYear() - index)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()

    const fetchData = async () => {
      setIsLoading(true)
      try {
        const response = await axiosPrivate.get(
          '/totalEmission/total-emission-amount',
          {
            signal: controller.signal,
          },
        )

        if (isMounted) {
          setData(response.data)
        }
      } catch (err) {
        if (axios.isCancel(err)) {
          // Log for debugging purposes
          // console.log('Request cancelled:', err.message);
        } else {
          console.error('Request failed:', err)
          // Only navigate if the error was not a cancellation
          // Check for a 403 status code specifically
          if (err.response && err.response.status === 403) {
            navigate('/sign-in', { state: { from: location }, replace: true })
          }
        }
      }
      setIsLoading(false)
    }

    fetchData()

    return () => {
      isMounted = false
      controller.abort()
      // console.log('Cleanup: Cancelled any ongoing requests.');
    }
  }, [navigate, location, axiosPrivate])

  function handleAddNewSubmit(e) {
    e.preventDefault()
  }

  // function pieChartRender(labelColor, value) {
  //   var chart = document.querySelector('#chartpie')
  //   chart.innerHTML = ''
  //   var options = {
  //     series: value ?? [0],
  //     chart: {
  //       height: 300,
  //       type: 'pie',
  //     },
  //     labels: [`Scope 1 (tonnes CO2e): ${value[0] ?? 0}`, `Scope 2 (tonnes CO2e): ${value[1] ?? 0}`, `Scope 3 (tonnes CO2e): ${value[2] ?? 0}`],
  //     legend: {
  //       show: true,
  //       position: 'right',
  //       horizontalAlign: 'left',
  //       floating: false,
  //       fontSize: '14px',
  //       fontFamily: 'Inter',
  //       fontWeight: 400,
  //       labels: {
  //         colors: labelColor,
  //         useSeriesColors: false,
  //       },
  //     },
  //     responsive: [
  //       {
  //         breakpoint: 480,
  //         options: {
  //           chart: {
  //             width: 200,
  //           },
  //           legend: {
  //             position: 'bottom',
  //           },
  //         },
  //       },
  //     ],
  //   }

  //   var chart = new ApexCharts(chart, options)
  //   chart.render()
  // }

  // function lineChartRender(labelColor) {
  //   var chart = document.querySelector('#chartline')
  //   chart.innerHTML = ''
  //   var options = {
  //     series: [
  //       {
  //         name: 'kg CO2',
  //         data: [28, 96, 33, 51, 76, 32, 100],
  //       },
  //     ],
  //     chart: {
  //       height: 350,
  //       type: 'line',
  //       zoom: {
  //         type: 'x',
  //         enabled: true,
  //         autoScaleYaxis: true,
  //       },
  //       dropShadow: {
  //         enabled: true,
  //         color: '#000',
  //         top: 18,
  //         left: 7,
  //         blur: 10,
  //         opacity: 0.2,
  //       },
  //       toolbar: {
  //         autoSelected: 'zoom',
  //         style: {
  //           color: 'white',
  //         },
  //       },
  //     },
  //     colors: ['#77B6EA'],
  //     dataLabels: {
  //       enabled: true,
  //     },
  //     title: {
  //       text: 'Moving Average Graph',
  //       align: 'left',
  //       style: {
  //         fontWeight: 'bold',
  //         color: labelColor,
  //       },
  //     },
  //     grid: {
  //       borderColor: '#e7e7e7',
  //       row: {
  //         colors: ['transparent'], // takes an array which will be repeated on columns
  //         opacity: 0.5,
  //       },
  //     },
  //     markers: {
  //       size: 1,
  //     },
  //     xaxis: {
  //       categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
  //       title: {
  //         text: 'Months',
  //         style: {
  //           color: labelColor,
  //           fontWeight: '500',
  //           fontSize: '12px',
  //         },
  //       },
  //       labels: {
  //         style: {
  //           colors: labelColor,
  //         },
  //       },
  //     },
  //     yaxis: {
  //       title: {
  //         text: 'Temperature',
  //         style: {
  //           color: labelColor,
  //           fontWeight: '500',
  //           fontSize: '14px',
  //         },
  //       },
  //       labels: {
  //         style: {
  //           colors: labelColor,
  //         },
  //       },
  //     },
  //     legend: {
  //       position: 'top',
  //       horizontalAlign: 'right',
  //       floating: true,
  //       offsetY: -25,
  //       offsetX: -5,
  //     },
  //   }

  //   var chart = new ApexCharts(chart, options)
  //   chart.render()
  // }

  return (
    //flex flex-col
    <div className="bg-mainbg dark:bg-maindarkbg w-full h-full overflow-y-auto max-h-full max-w-full">
      <div className="justify-start ml-4 mt-4">
        <form onSubmit={handleAddNewSubmit} className='flex gap-x-4'>
          {/* Company Site Dropdown */}
          <div>
            <select
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              value={site}
              onChange={(e) => setSite(e.target.value)}
              required
            >
              <option value="">Company/Site</option>
              <option value="Site 1">
                Site 1
              </option>
              <option value="Site 2">Site 2</option>
            </select>
          </div>

          {/* Year Dropdown */}
          <div>
            <select
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              value={year}
              onChange={(e) => setYear(e.target.value)}
              required
            >
              <option value=""><IoCalendarOutline className='mr-2'/> Year</option>
              {yearsOption.map((year) => (
             <option key={year} value={year}>
               {year}
             </option>
           ))}
            </select>
          </div>
        </form>
      </div>
      {/* <DateRangePicker dateRange={dateRange} setDateRange={setDateRange} /> */}
      <div className="grid pl-4 pr-4 font-['Inter'] rounded-3xl pt-4 text-maindarkbg dark:text-mainbg">
        {/* mb-4 pb-10 px-8 mx-4 */}
        <div className="grid grid-cols-12 gap-6">
          <div className="grid grid-cols-12 col-span-12 gap-6 xxl:col-span-9">
            <div className="col-span-12 md:col-span-6 ">
              <div className="bg-white shadow-lg items-center justify-around flex flex-col lg:flex-row border dark:bg-darkbg4 border-gray-300 rounded-md h-full py-4 pr-0 lg:pr-8">
                <ApexGaugeChart
                  amount={10}
                  maxAmount={100}
                  title={'Total Emission'}
                />
                <div className="flex flex-col gap-y-5">
                  <div className="text-lg">Total Emissions</div>
                  <div className="font-black text-4xl">
                    4.510k
                    <span className="font-semibold text-lg ml-3"> tCO2e </span>
                  </div>
                  <div className="font-semibold flex items-center gap-3 text-lg">
                    YoY <IoTriangle className="text-red-500" />
                    49.4%
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-12 md:col-span-6">
              <div className="bg-white shadow-lg items-center justify-around flex flex-col lg:flex-row border dark:bg-darkbg4 border-gray-300 rounded-md h-full py-4 pr-0 lg:pr-8">
                <ApexGaugeChart
                  amount={57}
                  maxAmount={100}
                  title={'Carbon Offset (tCO2e)'}
                />
                <div className="flex flex-col items-center lg:items-start gap-y-5">
                  <div className="text-lg">Carbon Offset (tCO2e)</div>
                  <div className="font-black text-4xl mr-6">
                    4.510k
                    <span className="font-semibold text-lg ml-3"> tCO2e </span>
                  </div>
                  <div className="font-semibold flex items-center gap-3 text-lg">
                    YoY <IoTriangle className="text-green-500" />
                    49.4%
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-12 grid-none grid-cols-none md:grid md:grid-cols-12 gap-6 h-auto">
              <div className="col-span-12 md:col-span-6 bg-white shadow-lg flex flex-col items-center border dark:bg-darkbg4 border-gray-300 rounded-md max-h-96 py-4 gap-6">
                <span className="font-semibold text-lg items-start flex justify-start text-start">
                  Scope Breakdown % of Total Emission
                </span>
                <MeterChart
                  Icon={IoCarSportOutline}
                  scope={1}
                  amount={data?.scope1}
                  maxAmount={data?.total}
                  barColor={'bg-scope1'}
                />
                <MeterChart
                  Icon={FaRegLightbulb}
                  scope={2}
                  amount={data?.scope2}
                  maxAmount={data?.total}
                  barColor={'bg-scope2'}
                />
                <MeterChart
                  Icon={IoCartOutline}
                  scope={1}
                  amount={data?.scope3}
                  maxAmount={data?.total}
                  barColor={'bg-scope3'}
                />
              </div>
              <div className="grid grid-rows-5 md:grid-rows-none gap-6 col-span-12 md:col-span-6 h-auto md:h-full max-h-96 mt-6 md:mt-0">
                <div className="row-span-1 md:row-span-2 grid grid-cols-2 bg-white shadow-lg items-center justify-center flex border dark:bg-darkbg4 border-gray-300 rounded-md h-auto md:max-h-none md:min-h-36">
                  <span className="font-semibold text-base md:text-lg flex items-center justify-center">
                    Carbon Footprint <br></br>per Employee
                  </span>
                  <span className="font-semibold text-base md:text-lg flex items-center justify-center">
                    <span className="font-black text-2xl md:text-4xl mr-6">4.510k </span>
                    tCO2e
                  </span>
                </div>
                <TableBox className = "row-span-3" data={data} rowSpan = {"row-span-4 md:row-span-3"}/>
              </div>
            </div>
            <div className="col-span-12 border border-gray-300 rounded-md shadow-lg dark:bg-darkbg4 mb-10">
              <div className="overflow-hidden sm:rounded-lg bg-white dark:bg-darkbg4 p-6 flex-col">
                <ApexColumnChart data={''} title = {"Monthly Emission by Scopes"}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Statistics
