import React from 'react'
import { IoCalendarOutline } from 'react-icons/io5'
import { useState } from 'react'
import { DateTime } from 'luxon';

const DateRangePicker = ({ dateRange, setDateRange }) => {
  const [showContentModal, setShowContentModal] = useState(false)
  
  function handleSubmit(e) {
    e.preventDefault()
    setShowContentModal(false)
  }

  return (
    <div className="flex justify-end">
      <button
        onClick={() => setShowContentModal(true)}
        type="button"
        className="bg-white border whitespace-pre border-gray-300 dark:bg-darkbg4 dark:text-gray-300 justify-end items-center flex gap-3 rounded-md w-fit mt-4 py-1 px-4 mr-4 hover:bg-gray-50 dark:hover:bg-gray-700"
      >
        <IoCalendarOutline />{' '}
        {dateRange.start !== ""
          ? `${DateTime.fromISO(dateRange.start).toLocaleString(DateTime.DATE_MED)}   -   ${DateTime.fromISO(dateRange.end).toLocaleString(DateTime.DATE_MED)}`
          : 'Pick the Date Range'}
      </button>
      {showContentModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-md flex justify-center items-center p-4 z-10 text-black dark:text-white">
          <div className="bg-white dark:bg-gray-800 w-full max-w-lg mx-auto rounded shadow-lg overflow-hidden p-6 max-h-[90vh] overflow-y-auto">
            <h2 className="text-lg font-semibold mb-4">
              Pick the Start Date and End Date
            </h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                  Start Date
                </label>
                <input
                  type="month"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                  value={dateRange.start}
                  onChange={(e) =>
                    setDateRange({
                      ...dateRange,
                      start: e.target.value,
                    })
                  }
                  required
                />
              </div>

              <div className="mb-4">
                <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                  End Date
                </label>
                <input
                  type="month"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                  value={dateRange.end}
                  onChange={(e) =>
                    setDateRange({
                      ...dateRange,
                      end: e.target.value,
                    })
                  }
                  required
                />
              </div>

              <div className="flex justify-end mt-4">
                <button
                  type="button"
                  onClick={() => setShowContentModal(false)}
                  className="bg-gray-300 dark:bg-gray-600 dark:text-gray-200 px-4 py-2 rounded mr-2"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-blue-500 text-white px-4 py-2 rounded"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  )
}

export default DateRangePicker
