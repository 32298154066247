import React, { useEffect, useState } from 'react'
import { ApexLineChart, ApexPieChart, BoxCard } from './component/Charts'
import { useLocation, useNavigate } from 'react-router-dom'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import axios from 'axios'

import { FaRegLightbulb } from 'react-icons/fa'
import { IoCartOutline } from 'react-icons/io5'
import { IoMdCar } from "react-icons/io";
import ApexColumnChart from './component/Charts/ApexColumnChart'
import { DateRangePicker } from '../Table/component/Table'

const SiteEmission = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const axiosPrivate = useAxiosPrivate()

  const [data, setData] = useState([])
  const [dateRange, setDateRange] = useState({start: '', end: ''})
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()

    const fetchData = async () => {
      setIsLoading(true)
      try {
        const response = await axiosPrivate.get(
          '/totalEmission/total-emission-amount',
          {
            signal: controller.signal,
          },
        )

        if (isMounted) {
          setData(response.data)
        }
      } catch (err) {
        if (axios.isCancel(err)) {
          // Log for debugging purposes
          // console.log('Request cancelled:', err.message);
        } else {
          console.error('Request failed:', err)
          // Only navigate if the error was not a cancellation
          // Check for a 403 status code specifically
          if (err.response && err.response.status === 403) {
            navigate('/sign-in', { state: { from: location }, replace: true })
          }
        }
      }
      setIsLoading(false)
    }

    fetchData()

    return () => {
      isMounted = false
      controller.abort()
      // console.log('Cleanup: Cancelled any ongoing requests.');
    }
  }, [navigate, location, axiosPrivate])

  return (
    //flex flex-col
    <div className="bg-mainbg dark:bg-maindarkbg w-full h-full overflow-y-auto max-h-full max-w-full">
      <div className="justify-between flex">
        <h1 className='ml-4 mt-4 text-maindarkbg dark:text-mainbg font-bold text-2xl'>Site Emission 1</h1>
        <DateRangePicker dateRange={dateRange} setDateRange={setDateRange}/>
      </div>
      {/* <DateRangePicker dateRange={dateRange} setDateRange={setDateRange} /> */}
      <div className="grid pl-4 pr-4 font-['Inter'] rounded-3xl pt-4 text-maindarkbg dark:text-mainbg">
        {/* mb-4 pb-10 px-8 mx-4 */}
        <div className="grid grid-cols-12 gap-6">
          <div className="grid grid-cols-12 col-span-12 gap-6 xxl:col-span-9">
            <div className="col-span-12">
              <div className="grid grid-cols-3 gap-6">
                <BoxCard
                  title={'Scope 1'}
                  value={data?.scope1 || 0}
                  unit={'tonnes CO2e'}
                  Icon={IoMdCar}
                  link="#"
                  extraColor={'bg-scope1'}
                />
                <BoxCard
                  title={'Scope 2'}
                  value={data?.scope2 || 0}
                  unit={'tonnes CO2e'}
                  Icon={FaRegLightbulb}
                  link="#"
                  extraColor={'bg-scope2'}
                />
                <BoxCard
                  title={'Scope 3'}
                  value={data?.scope3 || 0}
                  unit={'tonnes CO2e'}
                  Icon={IoCartOutline}
                  link="#"
                  extraColor={'bg-scope3'}
                />
              </div>
            </div>

            <div className="col-span-12 lg:col-span-4">
                <ApexPieChart data = {[12, 35, 98]} title={"Scope 1 Breakdown"}/>
            </div>
            <div className="col-span-12 lg:col-span-4">
                <ApexPieChart data = {[12, 35, 98]} title={"Scope 2 Breakdown"}/>
            </div>
            <div className="col-span-12 lg:col-span-4">
                <ApexPieChart data = {[12, 35, 98]} title={"Scope 3 Breakdown"}/>
            </div>

            <div className="col-span-12 border border-gray-300 rounded-md shadow-lg dark:bg-darkbg4">
              <div className="overflow-hidden sm:rounded-lg bg-white dark:bg-darkbg4 p-6 flex-col">
                <ApexLineChart data={''} title = {"Monthly Emission by Scopes"}/>
              </div>
            </div>

            <div className="col-span-12 border border-gray-300 rounded-md shadow-lg dark:bg-darkbg4 mb-10">
              <div className="overflow-hidden sm:rounded-lg bg-white dark:bg-darkbg4 p-6 flex-col">
                <ApexColumnChart data={''} title = {"Monthly Emission by Scopes"}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SiteEmission
