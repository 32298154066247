import { useState } from 'react'
import {
  EnergyPurchasesCard,
  ElectricVehicleCard,
} from './component/Dashboard/ScopeII'

import GuidelineModalToggleButton from './component/Dashboard/GuidelineModalToggleButton'
import GuidelineModal from './component/Dashboard/GuidelineModal'
import { DateRangePicker } from '../Table/component/Table'
import {
  ApexColumnChart,
  ApexLineChart,
  ApexPieChart,
} from './component/Charts'

const ScopeII = () => {
  const [isModalOpen, setModalOpen] = useState(false)

  const handleToggleModal = () => setModalOpen(!isModalOpen)

  return (
    <div className="flex flex-col bg-mainbg dark:bg-maindarkbg w-full h-full overflow-y-auto max-h-full max-w-full">
      <div className="justify-between flex">
        <h1 className="ml-4 mt-4 text-maindarkbg dark:text-mainbg font-bold text-2xl">
          Scope 2: Energy Purchase & Electric Vehicle
        </h1>
        <DateRangePicker
          dateRange={'dateRange'}
          setDateRange={'setDateRange'}
        />
      </div>

      <div className="grid pl-4 pr-4 font-['Inter'] rounded-3xl pt-4 text-maindarkbg dark:text-mainbg">
        <div className="grid grid-cols-12 gap-6">
          <div className="col-span-12 lg:col-span-6">
            <ApexPieChart data={[12, 35, 98]} title={'Total Emission'} />
          </div>
          <div className="col-span-12 lg:col-span-6 border sm:rounded-lg border-gray-300">
            <ApexColumnChart data={''} title = {"Category Breakdown"}/>
          </div>
          <div className="col-span-12 lg:col-span-6 border sm:rounded-lg border-gray-300">
            <div className="overflow-hidden sm:rounded-lg bg-white dark:bg-darkbg4 p-6 flex-col">
              <ApexLineChart data={''} title = {"Monthly Energy Purchase"}/>
            </div>
          </div>
          <div className="col-span-12 lg:col-span-6 border sm:rounded-lg border-gray-300">
            <div className="overflow-hidden sm:rounded-lg bg-white dark:bg-darkbg4 p-6 flex-col">
              <ApexLineChart data={''} title = {"Monthly Electric Vehicle"} />
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col lg:flex-row justify-around items-center pt-10 px-1 lg:pl-15 xl:pl-20 mb-10">
        <EnergyPurchasesCard />
        <ElectricVehicleCard />
      </div>
      <GuidelineModal
        isOpen={isModalOpen}
        onClose={handleToggleModal}
        title="Modal Title"
        subtitle="Modal Subtitle"
        content={<p>This is a guideline for scope II</p>}
      />
    </div>
  )
}

export default ScopeII
