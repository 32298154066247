import React, { useEffect, useState } from 'react';
import { EmissionDate, ReportingTable } from './component/Table';
import { columnDefinitionMobileCombustion } from './component/Table/column';
import { FiPlus, FiTrash } from 'react-icons/fi';
import { useLocation, useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import axios from 'axios';
import * as types from "./reportData.json"

const ReportMobileCombustion = () => {
  const column = columnDefinitionMobileCombustion;
  const navigate = useNavigate();
  const location = useLocation();
  const {bypassModal} = (location.state === null ? false : location.state)
  const axiosPrivate = useAxiosPrivate();
  const reportData = types

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showContentModal, setShowContentModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [modalMode, setModalMode] = useState(null); // 'add' or 'edit'
  const [editableData, setEditableData] = useState({
    emissionDate: '',
    vehicleMode: '',
    vehicleType: '',
    reportBy: '',
    calculateBy: '',
    fuel: '',
    quantity: '',
    fuelCost: 0,
    fuelEfficiency: 0,
    haulTonnage: 0,
    unit: '',
    details: '',
  });
  const [selectedRows, setSelectedRows] = useState(new Set());
  const [rowToDelete, setRowToDelete] = useState(null);
  const quantityType = {
    "Fuel Basis": "Fuel Consumed",
    "Distance Basis" : "Distance Travelled",
    "Spend Basis" : "Amount Spent on Fuel"
  }


  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await axiosPrivate.get(
          'mobileCombustion/read-S1B-records',
          {
            signal: controller.signal,
          }
        );

        if (isMounted) {
          // console.log(response.data);
          setData(response.data);
        }
      } catch (err) {
        if (axios.isCancel(err)) {
          // Log for debugging purposes
          // console.log('Request cancelled:', err.message);
        } else {
          console.error('Request failed:', err);
          // Only navigate if the error was not a cancellation
          // Check for a 403 status code specifically
          if (err.response && err.response.status === 403) {
            navigate('/sign-in', { state: { from: location }, replace: true });
          }
        }
      }
      setIsLoading(false);
    };

    fetchData();

    if (bypassModal == true){
      handleAddNewClick()
    }

    return () => {
      isMounted = false;
      controller.abort();
      // console.log('Cleanup: Cancelled any ongoing requests.');
    };
  }, [navigate, location, axiosPrivate]);

  const handleAddNewClick = () => {
    setEditableData({
    emissionDate: '',
    vehicleMode: '',
    vehicleType: '',
    reportBy: '',
    calculateBy: '',
    fuel: '',
    quantity: '',
    fuelCost: 0,
    fuelEfficiency: 0,
    haulTonnage: 0,
    unit: '',
    details: '',
    });
    setModalMode('add');
    setShowContentModal(true);
  };

  const handleDeleteClick = () => {
    if (selectedRows.size > 0) {
      setShowDeleteModal(true);
    } else {
      alert('No rows selected'); // Or show a more user-friendly message
    }
  };

  const requestRowDeletion = (rowId) => {
    setRowToDelete(rowId);
    setShowDeleteModal(true);
  };

  const confirmDelete = async () => {
    if (rowToDelete !== null) {
      let isMounted = true;
      const controller = new AbortController();

      try {
        await axiosPrivate.delete(`/mobileCombustion/delete-S1B-record/${rowToDelete}`, {
          headers: { 'Content-Type': 'application/json' },
          signal: controller.signal,
        });

        if (isMounted) {
          // Update the state to reflect the deletion
          setData((prevData) =>
            prevData.filter((row) => row.id !== rowToDelete)
          );
          setRowToDelete(null);
        }
      } catch (err) {
        if (!axios.isCancel(err)) {
          console.error('Deletion failed:', err);
          if (err.response) {
            // Redirect on 403 Forbidden or show an error message
            if (err.response.status === 403) {
              navigate('/sign-in', {
                state: { from: location },
                replace: true,
              });
            } else {
              alert(
                `Error: ${err.response.data.message || 'An error occurred.'}`
              );
            }
          }
        }
      } finally {
        if (isMounted) {
          setShowDeleteModal(false); // Close the modal upon completion or error
        }
      }

      // Cleanup function to prevent state updates if the component unmounts
      return () => {
        isMounted = false;
        controller.abort();
      };
    } else {
      // If rowToDelete is null, just close the modal
      setShowDeleteModal(false);
    }
  };

  const deleteSelectedRows = async () => {
    // Check if any rows are selected for deletion
    if (selectedRows.size > 0) {
      let isMounted = true;
      const controller = new AbortController();

      try {
        const recordIdsToDelete = Array.from(selectedRows);

        await axiosPrivate.delete('/mobileCombustion/delete-S1B-records', {
          data: { ids: recordIdsToDelete }, // Sending user IDs in the request body
          headers: { 'Content-Type': 'application/json' },
          signal: controller.signal,
        });

        if (isMounted) {
          // Filter out the deleted users from the current data
          setData((prevData) =>
            prevData.filter((row) => !selectedRows.has(row.id))
          );
          setSelectedRows(new Set()); // Clear selected rows after successful deletion
        }
      } catch (err) {
        if (!axios.isCancel(err)) {
          console.error('Deletion failed:', err);
          if (err.response) {
            // Handle specific HTTP status codes here
            if (err.response.status === 403) {
              navigate('/sign-in', {
                state: { from: location },
                replace: true,
              });
            } else {
              alert(
                `Error: ${err.response.data.message || 'An error occurred.'}`
              );
            }
          }
        }
      } finally {
        if (isMounted) {
          setShowDeleteModal(false); // Close the modal upon completion or error
        }
      }

      // Cleanup function to prevent state updates if the component unmounts
      return () => {
        isMounted = false;
        controller.abort();
      };
    } else {
      // If no rows were selected, just close the modal
      setShowDeleteModal(false);
    }
  };

  // Function to handle edit requests
  const handleEditClick = (rowData) => {
    setEditableData(rowData);
    setModalMode('edit');
    setShowContentModal(true);
  };

  const handleAddNewSubmit = async (event) => {
    event.preventDefault();

    const endpoint =
      modalMode === 'edit'
        ? `/mobileCombustion/edit-S1B-record/${editableData.id}`
        : '/mobileCombustion/create-S1B-record';
    const method = modalMode === 'edit' ? 'patch' : 'post';

    // Prepare the data payload

    const payload = {
      scope: 1,
      category: 'mobileCombustion',
      emissionDate: editableData.emissionDate,
      vehicleMode: editableData.vehicleMode,
      vehicleType: editableData.vehicleType,
      reportBy: editableData.reportBy,
      calculateBy: editableData.calculateBy,
      fuel: editableData.fuel,
      quantity: editableData.quantity,
      fuelCost: editableData.fuelCost,
      fuelEfficiency: editableData.fuelEfficiency,
      haulTonnage: editableData.haulTonnage,
      unit: editableData.unit,
      details: editableData.details,
    };

    try {
      const response = await axiosPrivate[method](endpoint, payload, {
        headers: { 'Content-Type': 'application/json' },
      });
      // Update the state based on the modal mode
      if (modalMode === 'edit') {
        setData((currentData) =>
          currentData.map((item) =>
            item.id === editableData.id ? { ...item, ...response.data } : item
          )
        );
      } else {
        setData((currentData) => [...currentData, response.data]);
      }

      // Reset form and close modal
      setEditableData({
        emissionDate: '',
        vehicleMode: '',
        vehicleType: '',
        reportBy: '',
        calculateBy: '',
        fuel: '',
        quantity: '',
        fuelCost: 0,
        fuelEfficiency: 0,
        haulTonnage: 0,
        unit: '',
        details: '',
      });
      setShowContentModal(false);
    } catch (err) {
      if (!axios.isCancel(err)) {
        console.error('Submission failed:', err);
        const errorMsg = err.response?.data.message || 'An error occurred.';
        if (err.response?.status === 403) {
          navigate('/sign-in', { state: { from: location }, replace: true });
        } else {
          alert(`Error: ${errorMsg}`);
        }
      }
    }
  };

  function unitOption() {
    const options = {
      "Fuel Consumption" : ['Litres', 'Tonnes'],
      "Distance Travelled" : ["Kilometres"],
      "Amount Spent" : ["Malaysian Ringgit"],
      "Quantity Consumed": ['Litres', 'Tonnes']
    }

    if (editableData.reportBy === "Fuel Consumption"){
      return options[editableData.calculateBy];
    }
    else {
      return options[editableData.reportBy];
    }
  }

  
  function quantityLabel() {
    const labels = {
      'Quantity Consumed': 'Fuel Consumed',
      'Distance Travelled': "Distance Travelled (km)",
      'Amount Spent': "Amount Spent (RM)",
    }

    if (editableData.reportBy === "Fuel Consumption"){
      return labels[editableData.calculateBy]
    }
    else{
      return labels[editableData.reportBy]
    }
  }

  if (isLoading) {
    return <div>Loading...</div>; // Or any loading spinner
  }

  return (
    <div className="flex flex-col bg-mainbg dark:bg-maindarkbg text-black dark:text-white p-4 md:p-10 w-full overflow-y-auto h-full">
      <div className="flex flex-col md:flex-row justify-between items-center mb-4">
        <h2 className="text-xl md:text-2xl font-bold mb-4 md:mb-0 dark:text-white">
          Mobile Combustion Reporting
        </h2>
        <div className="flex space-x-2">
          <button
            onClick={handleAddNewClick}
            className="flex items-center justify-center bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700 dark:bg-blue-600 dark:hover:bg-blue-800 transition duration-300 w-40"
            aria-label="Add new item"
          >
            <FiPlus className="mr-2" />
            <span className="flex-1 text-center">Add</span>
          </button>
          <button
            onClick={handleDeleteClick}
            className="flex items-center justify-center bg-red-500 text-white py-2 px-4 rounded hover:bg-red-700 dark:bg-red-600 dark:hover:bg-red-800 transition duration-300 w-40"
            aria-label="Delete selected items"
          >
            <FiTrash className="mr-2" />
            <span className="flex-1 text-center">Delete</span>
          </button>
        </div>
      </div>
      {/* {showContentModal && <MyModal onClose={() => setShowContentModal(false)} />} */}
      <ReportingTable
        data={data}
        columns={column}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        onRequestDelete={requestRowDeletion}
        onEdit={handleEditClick}
      />
      {showDeleteModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-md flex justify-center items-center p-4">
          <div className="bg-white dark:bg-gray-800 w-full max-w-md mx-auto rounded shadow-lg overflow-hidden p-4">
            <p className="text-gray-800 dark:text-gray-200 text-center text-lg md:text-base">
              Are you sure you want to delete the selected items?
            </p>
            <div className="flex justify-end mt-4">
              <button
                onClick={() => setShowDeleteModal(false)}
                className="bg-gray-300 dark:bg-gray-600 dark:text-gray-200 px-4 py-2 rounded mr-2 text-sm md:text-base hover:bg-gray-400 dark:hover:bg-gray-700 transition duration-300"
              >
                Cancel
              </button>
              <button
                onClick={() =>
                  rowToDelete !== null ? confirmDelete() : deleteSelectedRows()
                }
                className="bg-red-500 text-white px-4 py-2 rounded text-sm md:text-base hover:bg-red-600 transition duration-300"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
      {showContentModal && (
        <div className="fixed inset-0 bg-black border-rounded border bg-opacity-50 backdrop-blur-md flex justify-center items-center p-4 z-40">
          <div className="bg-white dark:bg-gray-800 w-full max-w-lg mx-auto rounded shadow-lg overflow-hidden p-6 max-h-[90vh] overflow-y-auto">
            <h2 className="text-lg font-semibold mb-4">
              {modalMode === 'edit' ? 'Edit Data' : 'Add New Data'}
            </h2>
            <form onSubmit={handleAddNewSubmit}>
              {/* Emission Date Input */}
              <EmissionDate data={editableData} setEditableData = {setEditableData}/>

            {/* Vehicle Mode Dropdown*/}
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Vehicle Mode
                  </label>
                  <select
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.vehicleMode}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        vehicleMode: e.target.value,
                      })
                    }
                    required
                  >
                    <option value="">Select Mode</option>
                    {Object.keys(reportData.vehicleMode).map((vehicle) => (
                      <option key={vehicle} value={vehicle}>
                        {vehicle}
                      </option>
                    ))}
                  </select>
                </div>
              

              {/* Vehicle Type Dropdown*/}
              {editableData.vehicleMode !== "" && (
                <div className="mb-4">
                <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                  Vehicle Type
                </label>
                <select
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                  value={editableData.vehicleType}
                  onChange={(e) =>
                    setEditableData({
                      ...editableData,
                      vehicleType: e.target.value,
                    })
                  }
                  required
                >
                  <option value="">Select Type</option>
                  {reportData.vehicleMode[editableData.vehicleMode].map((vehicle) => (
                    <option key={vehicle} value={vehicle}>
                      {vehicle}
                    </option>
                  ))}
                </select>
              </div>
              )}
                
              

                <div className="mb-4">
                <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                  Report By
                </label>
                <select
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                  value={editableData.reportBy}
                  onChange={(e) =>
                    setEditableData({
                      ...editableData,
                      reportBy: e.target.value,
                      calculateBy: '',
                      fuel: '',
                      quantity: '',
                      fuelCost: 0,
                      fuelEfficiency: 0,
                      haulTonnage: 0,
                      unit: '',
                    })
                  }
                  required
                >
                  <option value="">Select Type</option>
                  <option value="Fuel Consumption">Fuel Consumption</option>
                  <option value="Distance Travelled">Distance Travelled</option>
                  <option value="Amount Spent">Amount Spent</option>
                </select>
              </div>

              {/* CalculateBy Dropdown */}
              {editableData.reportBy == "Fuel Consumption" &&
                <div className="mb-4">
                <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                  Calculate By
                </label>
                <select
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                  value={editableData.calculateBy}
                  onChange={(e) =>
                    setEditableData({
                      ...editableData,
                      calculateBy: e.target.value,
                      fuel: '',
                      quantity: '',
                      fuelCost: 0,
                      fuelEfficiency: 0,
                      haulTonnage: 0,
                      unit: '',
                    })
                  }
                  required
                >
                  <option value="">Select Type</option>
                  <option value="Quantity Consumed">Quantity Consumed</option>
                  <option value="Distance Travelled">Distance Travelled</option>
                  <option value="Amount Spent">Amount Spent</option>
                </select>
              </div>
              }


              {/* Fuel Dropdown */}
              {(editableData.reportBy != "Spend Basis" && editableData.vehicleType && editableData.vehicleMode) && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Fuel Type
                  </label>
                  <select
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.fuel}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        fuel: e.target.value
                      })
                    }
                    required
                  >
                    <option value="">Select Fuel</option>
                      {
                      reportData.fuel.map((fuel) => (
                        <option key={fuel} value={fuel}>
                          {fuel}
                        </option>
                      ))
                    }
                  </select>
                </div>
              )}

              {/* Fuel Cost Input */}
              {(editableData.reportBy && editableData.reportBy === "Fuel Consumption" && editableData.calculateBy && editableData.calculateBy === "Amount Spent") && (
                <div className="mb-4">
                <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                  Fuel Cost (RM / Litre)
                </label>
                <input
                  type="number"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                  value={editableData.fuelCost}
                  onWheel={(e) => e.target.blur()}
                  onChange={(e) =>
                    setEditableData({
                      ...editableData,
                      fuelCost: e.target.value,
                    })
                  }
                  required
                />
              </div>
              )}

              {/* Quantity Input */}
              {((editableData.reportBy !== "Fuel Consumption" && editableData.reportBy) || editableData.calculateBy) && (
                <div className="mb-4">
                <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                  {quantityLabel()}
                </label>
                <input
                  type="number"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                  value={editableData.quantity}
                  onChange={(e) =>
                    setEditableData({
                      ...editableData,
                      quantity: e.target.value,
                    })
                  }
                  required
                />
              </div>
              )}

              {/* Haul Tonnage Input */}
              {(editableData.reportBy === "Distance Travelled") && (
                <div className="mb-4">
                <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                  Haul Tonnage (Tonnes)
                </label>
                <input
                  type="number"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                  value={editableData.haulTonnage}
                  onChange={(e) =>
                    setEditableData({
                      ...editableData,
                      haulTonnage: e.target.value,
                    })
                  }
                  required
                />
              </div>
              )}


              {/* Unit Dropdown */}
              {editableData.calculateBy === "Quantity Consumed" && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Fuel Unit
                  </label>
                  <select
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.unit}
                    onChange={(e) =>
                      setEditableData({ ...editableData, unit: e.target.value })
                    }
                    required
                  >
                    <option value = "">Select Unit</option>
                    {/* To adapt to the availability of fuel's emission factor */}
                    {editableData.fuel && Object.keys(reportData.fuelEF[editableData.fuel]["CO2"]).filter(key => (reportData.fuelEF[editableData.fuel]["CO2"][key] != 0)).map((unit) => (
                        <option key={unit} value={unit}>
                          {unit}
                        </option>
                      ))}
                  </select>
                </div>
              )}

              {/* Fuel Cost Input */}
              {(editableData.reportBy === "Fuel Consumption" && editableData.calculateBy && editableData.calculateBy === "Distance Travelled") && (
                <div className="mb-4">
                <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                  Fuel Efficiency (Litre / KM)
                </label>
                <input
                  type="number"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                  value={editableData.fuelEfficiency}
                  onChange={(e) =>
                    setEditableData({
                      ...editableData,
                      fuelEfficiency: e.target.value,
                    })
                  }
                  required
                />
              </div>
              )}

              {/* Other Fields */}
              {/* Details Input */}
              <div className="mb-4">
                <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                  Description
                </label>
                <textarea
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                  value={editableData.details}
                  onChange={(e) =>
                    setEditableData({
                      ...editableData,
                      details: e.target.value,
                    })
                  }
                  required
                />
              </div>

              <div className="flex justify-end mt-4">
                <button
                  type="button"
                  onClick={() => setShowContentModal(false)}
                  className="bg-gray-300 dark:bg-gray-600 dark:text-gray-200 px-4 py-2 rounded mr-2"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-blue-500 text-white px-4 py-2 rounded"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReportMobileCombustion;
