import React, { useEffect, useState } from 'react'
import { EmissionDate, ReportingTable } from './component/Table'
import { columnDefinitionFugitiveEmission } from './component/Table/column'
import { FiPlus, FiTrash } from 'react-icons/fi'
import { useLocation, useNavigate } from 'react-router-dom'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import axios from 'axios'
import * as types from './reportData.json'

const ReportFugitiveEmission = () => {
  const column = columnDefinitionFugitiveEmission
  const navigate = useNavigate()
  const location = useLocation()
  const { bypassModal } = location.state === null ? false : location.state
  const axiosPrivate = useAxiosPrivate()
  const { fugitiveEmissionEquipment, refrigerant, ghg } = types

  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [showContentModal, setShowContentModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [modalMode, setModalMode] = useState(null) // 'add' or 'edit'
  const [editableData, setEditableData] = useState({
    emissionDate: '',
    reportBy: '',
    equipmentType: '',
    refrigerant: '',
    materialBalance: { IB: 0, IE: 0, P: 0, S: 0, CB: 0, CE: 0 },
    simplifiedMaterialBalance: { PN: 0, CN: 0, PS: 0, CD: 0, RD: 0 },
    capacity: 0,
    installationQuantity: 0,
    operationQuantity: 0,
    operationPeriod: 0,
    disposalQuantity: 0,
    purchasedAmount: 0,
    description: '',
  })
  const [selectedRows, setSelectedRows] = useState(new Set())
  const [rowToDelete, setRowToDelete] = useState(null)

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()

    const fetchData = async () => {
      setIsLoading(true)
      try {
        const response = await axiosPrivate.get(
          'fugitiveEmission/read-S1C-records',
          {
            signal: controller.signal,
          },
        )

        if (isMounted) {
          // console.log(response.data);
          setData(response.data)
        }
      } catch (err) {
        if (axios.isCancel(err)) {
          // Log for debugging purposes
          // console.log('Request cancelled:', err.message);
        } else {
          console.error('Request failed:', err)
          // Only navigate if the error was not a cancellation
          // Check for a 403 status code specifically
          if (err.response && err.response.status === 403) {
            navigate('/sign-in', { state: { from: location }, replace: true })
          }
        }
      }
      setIsLoading(false)
    }

    fetchData()

    if (bypassModal === true) {
      handleAddNewClick()
    }

    return () => {
      isMounted = false
      controller.abort()
      // console.log('Cleanup: Cancelled any ongoing requests.');
    }
  }, [navigate, location, axiosPrivate])

  const handleAddNewClick = () => {
    setEditableData({
      emissionDate: '',
      reportBy: '',
      equipmentType: '',
      refrigerant: '',
      materialBalance: {},
      simplifiedMaterialBalance: {},
      capacity: 0,
      installationQuantity: 0,
      operationQuantity: 0,
      operationPeriod: 0,
      disposalQuantity: 0,
      purchasedAmount: 0,
      description: '',
    })
    setModalMode('add')
    setShowContentModal(true)
  }

  const handleDeleteClick = () => {
    if (selectedRows.size > 0) {
      setShowDeleteModal(true)
    } else {
      alert('No rows selected') // Or show a more user-friendly message
    }
  }

  const requestRowDeletion = (rowId) => {
    setRowToDelete(rowId)
    setShowDeleteModal(true)
  }

  const confirmDelete = async () => {
    if (rowToDelete !== null) {
      let isMounted = true
      const controller = new AbortController()

      try {
        await axiosPrivate.delete(`/fugitiveEmission/delete-S1C-record/${rowToDelete}`, {
          headers: { 'Content-Type': 'application/json' },
          signal: controller.signal,
        })

        if (isMounted) {
          // Update the state to reflect the deletion
          setData((prevData) =>
            prevData.filter((row) => row.id !== rowToDelete),
          )
          setRowToDelete(null)
        }
      } catch (err) {
        if (!axios.isCancel(err)) {
          console.error('Deletion failed:', err)
          if (err.response) {
            // Redirect on 403 Forbidden or show an error message
            if (err.response.status === 403) {
              navigate('/sign-in', {
                state: { from: location },
                replace: true,
              })
            } else {
              alert(
                `Error: ${err.response.data.message || 'An error occurred.'}`,
              )
            }
          }
        }
      } finally {
        if (isMounted) {
          setShowDeleteModal(false) // Close the modal upon completion or error
        }
      }

      // Cleanup function to prevent state updates if the component unmounts
      return () => {
        isMounted = false
        controller.abort()
      }
    } else {
      // If rowToDelete is null, just close the modal
      setShowDeleteModal(false)
    }
  }

  const deleteSelectedRows = async () => {
    // Check if any rows are selected for deletion
    if (selectedRows.size > 0) {
      let isMounted = true
      const controller = new AbortController()

      try {
        const recordIdsToDelete = Array.from(selectedRows)

        await axiosPrivate.delete('/fugitiveEmission/delete-S1C-records', {
          data: { ids: recordIdsToDelete }, // Sending user IDs in the request body
          headers: { 'Content-Type': 'application/json' },
          signal: controller.signal,
        })

        if (isMounted) {
          // Filter out the deleted users from the current data
          setData((prevData) =>
            prevData.filter((row) => !selectedRows.has(row.id)),
          )
          setSelectedRows(new Set()) // Clear selected rows after successful deletion
        }
      } catch (err) {
        if (!axios.isCancel(err)) {
          console.error('Deletion failed:', err)
          if (err.response) {
            // Handle specific HTTP status codes here
            if (err.response.status === 403) {
              navigate('/sign-in', {
                state: { from: location },
                replace: true,
              })
            } else {
              alert(
                `Error: ${err.response.data.message || 'An error occurred.'}`,
              )
            }
          }
        }
      } finally {
        if (isMounted) {
          setShowDeleteModal(false) // Close the modal upon completion or error
        }
      }

      // Cleanup function to prevent state updates if the component unmounts
      return () => {
        isMounted = false
        controller.abort()
      }
    } else {
      // If no rows were selected, just close the modal
      setShowDeleteModal(false)
    }
  }

  // Function to handle edit requests
  const handleEditClick = (rowData) => {
    setEditableData(rowData)
    setModalMode('edit')
    setShowContentModal(true)
  }

  const handleAddNewSubmit = async (event) => {
    event.preventDefault()

    const endpoint =
      modalMode === 'edit'
        ? `/fugitiveEmission/edit-S1C-record/${editableData.id}`
        : '/fugitiveEmission/create-S1C-record'
    const method = modalMode === 'edit' ? 'patch' : 'post'

    // Prepare the data payload
    const payload = {
      scope: 1,
      category: 'fugitiveEmission',
      emissionDate: editableData.emissionDate,
      reportBy: editableData.reportBy,
      equipmentType: editableData.equipmentType,
      refrigerant: editableData.refrigerant,
      materialBalance: editableData.materialBalance,
      simplifiedMaterialBalance: editableData.simplifiedMaterialBalance,
      capacity: editableData.capacity,
      installationQuantity: editableData.installationQuantity,
      operationQuantity: editableData.operationQuantity,
      operationPeriod: editableData.operationPeriod,
      disposalQuantity: editableData.disposalQuantity,
      purchasedAmount: editableData.purchasedAmount,
      description: editableData.description,
    }

    try {
      const response = await axiosPrivate[method](endpoint, payload, {
        headers: { 'Content-Type': 'application/json' },
      })

      // Update the state based on the modal mode
      if (modalMode === 'edit') {
        setData((currentData) =>
          currentData.map((item) =>
            item.id === editableData.id ? { ...item, ...response.data } : item,
          ),
        )
      } else {
        setData((currentData) => [...currentData, response.data])
      }

      // Reset form and close modal
      setEditableData({
        emissionDate: '',
        reportBy: '',
        equipmentType: '',
        refrigerant: '',
        materialBalance: {},
        simplifiedMaterialBalance: {},
        capacity: 0,
        installationQuantity: 0,
        operationQuantity: 0,
        operationPeriod: 0,
        disposalQuantity: 0,
        purchasedAmount: 0,
        description: '',
      })
      setShowContentModal(false)
    } catch (err) {
      if (!axios.isCancel(err)) {
        console.error('Submission failed:', err)
        const errorMsg = err.response?.data.message || 'An error occurred.'
        if (err.response?.status === 403) {
          navigate('/sign-in', { state: { from: location }, replace: true })
        } else {
          alert(`Error: ${errorMsg}`)
        }
      }
    }
  }

  function handleTypeChange(e, key) {
    const { name, value } = e.target
    let copyOfData = { ...editableData }
    copyOfData[name][key] = value
    setEditableData(copyOfData)
  }

  if (isLoading) {
    return <div>Loading...</div> // Or any loading spinner
  }

  return (
    <div className="flex flex-col bg-mainbg dark:bg-maindarkbg text-black dark:text-white p-4 md:p-10 w-full overflow-y-auto h-full">
      <div className="flex flex-col md:flex-row justify-between items-center mb-4">
        <h2 className="text-xl md:text-2xl font-bold mb-4 md:mb-0 dark:text-white">
          Fugitive Emission Reporting
        </h2>
        <div className="flex space-x-2">
          <button
            onClick={handleAddNewClick}
            className="flex items-center justify-center bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700 dark:bg-blue-600 dark:hover:bg-blue-800 transition duration-300 w-40"
            aria-label="Add new item"
          >
            <FiPlus className="mr-2" />
            <span className="flex-1 text-center">Add</span>
          </button>
          <button
            onClick={handleDeleteClick}
            className="flex items-center justify-center bg-red-500 text-white py-2 px-4 rounded hover:bg-red-700 dark:bg-red-600 dark:hover:bg-red-800 transition duration-300 w-40"
            aria-label="Delete selected items"
          >
            <FiTrash className="mr-2" />
            <span className="flex-1 text-center">Delete</span>
          </button>
        </div>
      </div>
      {/* {showContentModal && <MyModal onClose={() => setShowContentModal(false)} />} */}
      <ReportingTable
        data={data}
        columns={column}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        onRequestDelete={requestRowDeletion}
        onEdit={handleEditClick}
      />
      {showDeleteModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-md flex justify-center items-center p-4">
          <div className="bg-white dark:bg-gray-800 w-full max-w-md mx-auto rounded shadow-lg overflow-hidden p-4">
            <p className="text-gray-800 dark:text-gray-200 text-center text-lg md:text-base">
              Are you sure you want to delete the selected items?
            </p>
            <div className="flex justify-end mt-4">
              <button
                onClick={() => setShowDeleteModal(false)}
                className="bg-gray-300 dark:bg-gray-600 dark:text-gray-200 px-4 py-2 rounded mr-2 text-sm md:text-base hover:bg-gray-400 dark:hover:bg-gray-700 transition duration-300"
              >
                Cancel
              </button>
              <button
                onClick={() =>
                  rowToDelete !== null ? confirmDelete() : deleteSelectedRows()
                }
                className="bg-red-500 text-white px-4 py-2 rounded text-sm md:text-base hover:bg-red-600 transition duration-300"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
      {showContentModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-md flex justify-center items-center p-4">
          <div className="bg-white dark:bg-gray-800 w-full max-w-lg mx-auto rounded shadow-lg overflow-hidden p-6 max-h-[90vh] overflow-y-auto">
            <h2 className="text-lg font-semibold mb-4">
              {modalMode === 'edit' ? 'Edit Data' : 'Add New Data'}
            </h2>
            <form onSubmit={handleAddNewSubmit}>
              {/* Emission Date Input */}
              <EmissionDate data={editableData} setEditableData = {setEditableData}/>

              {/* Type Dropdown */}
              <div className="mb-4">
                <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                  Report By
                </label>
                <select
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                  value={editableData.reportBy}
                  onChange={(e) =>
                    setEditableData({
                      ...editableData,
                      reportBy: e.target.value,
                      equipmentType: '',
                      refrigerant: '',
                      materialBalance: {},
                      simplifiedMaterialBalance: {},
                      capacity: 0,
                      installationQuantity: 0,
                      operationQuantity: 0,
                      operationPeriod: 0,
                      disposalQuantity: 0,
                      purchasedAmount: 0,
                    })
                  }
                  required
                >
                  <option value="">Select Type</option>
                  <option value="Screening Method">Screening Method</option>
                  <option value="Purchased Gas Method">
                    Purchased Gas Method
                  </option>
                  <option value="Material Balance Method">
                    Material Balance Method
                  </option>
                  <option value="Simplified Material Balance Method">
                    Simplified Material Balance Method
                  </option>
                </select>
              </div>

              {/* Equipment Type Dropdown */}
              {editableData.reportBy === 'Screening Method' && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Equipment Type
                  </label>
                  <select
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.equipmentType}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        equipmentType: e.target.value,
                        unit: '',
                      })
                    }
                    required
                  >
                    <option value="">Select Type</option>
                    {Object.keys(fugitiveEmissionEquipment).map((type) => (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              {/* Capacity Input */}
              {editableData.reportBy === 'Screening Method' && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Capacity ({editableData.equipmentType ? fugitiveEmissionEquipment[editableData.equipmentType]['capacity']: ''}kg)
                  </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.capacity}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        capacity: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              )}

              {/* Years Input */}
              {editableData.reportBy === 'Purchased Gas Method' && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Number of Years
                  </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.capacity}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        capacity: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              )}

              {/* Refrigerant Dropdown */}
              {editableData.reportBy && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Refrigerant
                  </label>
                  <select
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.refrigerant}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        refrigerant: e.target.value,
                        unit: '',
                      })
                    }
                    required
                  >
                    <option value="">Select Type</option>
                    {editableData.reportBy === 'Purchased Gas Method' &&
                      ghg.map((type) => (
                        <option key={type} value={type}>
                          {type}
                        </option>
                      ))}
                    {refrigerant.map((type) => (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              {/* Amount Input */}
              {editableData.reportBy === 'Purchased Gas Method' && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Amount (kg)
                  </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.purchasedAmount}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        purchasedAmount: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              )}

              {/* Installation Quantity Input */}
              {editableData.reportBy === 'Screening Method' && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Installation Quantity
                  </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.installationQuantity}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        installationQuantity: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              )}

              {/* Operation Quantity Input */}
              {editableData.reportBy === 'Screening Method' && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Operation Quantity
                  </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.operationQuantity}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        operationQuantity: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              )}

              {/* Operation Period (month) Input */}
              {editableData.reportBy === 'Screening Method' && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Operation Period (month)
                  </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.operationPeriod}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        operationPeriod: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              )}

              {/* Disposal Quantity Input */}
              {editableData.reportBy === 'Screening Method' && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Disposal Quantity
                  </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.disposalQuantity}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        disposalQuantity: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              )}

              {editableData.reportBy === 'Material Balance Method' && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Refrigerant in Storage at the Beginning, I<sub>B</sub>
                  </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    name="materialBalance"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.materialBalance['IB']}
                    onChange={(e) => handleTypeChange(e, 'IB')}
                    required
                  />
                </div>
              )}

              {editableData.reportBy === 'Material Balance Method' && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Refrigerant in Storage at the End, I<sub>E</sub>
                  </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    name="materialBalance"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.materialBalance['IE']}
                    onChange={(e) => handleTypeChange(e, 'IE')}
                    required
                  />
                </div>
              )}

              {editableData.reportBy === 'Material Balance Method' && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Refrigerant Purchased or Acquired, P
                  </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    name="materialBalance"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.materialBalance['P']}
                    onChange={(e) => handleTypeChange(e, 'P')}
                    required
                  />
                </div>
              )}

              {editableData.reportBy === 'Material Balance Method' && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Refrigerant Sold or Disposed, S
                  </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    name="materialBalance"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.materialBalance['S']}
                    onChange={(e) => handleTypeChange(e, 'S')}
                    required
                  />
                </div>
              )}

              {editableData.reportBy === 'Material Balance Method' && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Total Refrigerant Capacity of Equipment at the Beginning, C
                    <sub>B</sub>
                  </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    name="materialBalance"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.materialBalance['CB']}
                    onChange={(e) => handleTypeChange(e, 'CB')}
                    required
                  />
                </div>
              )}

              {editableData.reportBy === 'Material Balance Method' && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Total Refrigerant Capacity of Equipment at the End, C
                    <sub>E</sub>
                  </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    name="materialBalance"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.materialBalance['CE']}
                    onChange={(e) => handleTypeChange(e, 'CE')}
                    required
                  />
                </div>
              )}

              {editableData.reportBy === 'Simplified Material Balance Method' && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                  Purchases of Refrigerant Used to Charge New Equipment, P<sub>N</sub>
                  </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    name="simplifiedMaterialBalance"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.simplifiedMaterialBalance['PN']}
                    onChange={(e) => handleTypeChange(e, 'PN')}
                    required
                  />
                </div>
              )}

              {editableData.reportBy === 'Simplified Material Balance Method' && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                  Total Refrigerant Capacity of the New Equipment, C<sub>N</sub>
                  </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    name="simplifiedMaterialBalance"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.simplifiedMaterialBalance['CN']}
                    onChange={(e) => handleTypeChange(e, 'CN')}
                    required
                  />
                </div>
              )}

              {editableData.reportBy === 'Simplified Material Balance Method' && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                  Purchases of Refrigerant Used to Service Equipment, P<sub>S</sub>
                  </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    name="simplifiedMaterialBalance"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.simplifiedMaterialBalance['PS']}
                    onChange={(e) => handleTypeChange(e, 'PS')}
                    required
                  />
                </div>
              )}

              {editableData.reportBy === 'Simplified Material Balance Method' && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                  Total Refrigerant Capacity of Retiring Equipment, C<sub>D</sub>
                  </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    name="simplifiedMaterialBalance"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.simplifiedMaterialBalance['CD']}
                    onChange={(e) => handleTypeChange(e, 'CD')}
                    required
                  />
                </div>
              )}

              {editableData.reportBy === 'Simplified Material Balance Method' && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                  Refrigerant Recovered from Retiring Equipment, R<sub>D</sub>
                  </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    name="simplifiedMaterialBalance"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.simplifiedMaterialBalance['RD']}
                    onChange={(e) => handleTypeChange(e, 'RD')}
                    required
                  />
                </div>
              )}

              {/* Description Input */}
              <div className="mb-4">
                <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                  Description
                </label>
                <textarea
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                  value={editableData.description}
                  onChange={(e) =>
                    setEditableData({
                      ...editableData,
                      description: e.target.value,
                    })
                  }
                  required
                />
              </div>

              <div className="flex justify-end mt-4">
                <button
                  type="button"
                  onClick={() => setShowContentModal(false)}
                  className="bg-gray-300 dark:bg-gray-600 dark:text-gray-200 px-4 py-2 rounded mr-2"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-blue-500 text-white px-4 py-2 rounded"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  )
}

export default ReportFugitiveEmission
