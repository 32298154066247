// Reference: https://www.youtube.com/watch?v=yAI9fbbH-rM
// https://apexcharts.com/react-chart-demos/column-charts/stacked-columns-100/

import { useEffect, useState } from 'react'
import Chart from 'react-apexcharts'
import { useTheme } from '../../../../contexts/ThemeContext'

const ApexColumnChart = ({ data, title }) => {
  const { theme } = useTheme()
  const [chartData, setChartData] = useState()

  useEffect(() => {
    if (theme === 'dark') {
      renderChart('#d1d5db')
    } else if (theme === 'light') {
      renderChart('#6b7280')
    }
  }, [theme])

  function renderChart(labelColor) {
    setChartData({
        series: [
          {
            name: 'Scope 1',
            data: [10, 12, 90, 44, 55, 57, 56, 61, 58, 63, 60, 66],
            color: "#FF8127"
          },
          {
            name: 'Scope 2',
            data: [20, 40, 50, 76, 85, 101, 98, 87, 105, 91, 114, 94],
            color: "#085AB0"
          },
          {
            name: 'Scope 3',
            data: [56, 22, 12, 35, 41, 36, 26, 45, 48, 52, 53, 41],
            color: "#009250"
          },
        ],
        options:{
          chart: {
            toolbar: {
              show: false
            }
          },
          legend: {
            show: true,
            position: 'top',
            horizontalAlign: 'left',
            floating: false,
            fontSize: '14px',
            fontFamily: 'Inter',
            fontWeight: 400,
            labels: {
              // colors: '#9ca3af',
              colors: labelColor,
              useSeriesColors: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'smooth'
          },
          xaxis: {
            title: {
              text: 'Months',
              style: {
                color: labelColor,
                fontWeight: '500',
                fontSize: '12px',
              },
            },
            categories: [
              'Jan',
              'Feb',
              'Mar',
              'Apr',
              'May',
              'Jun',
              'Jul',
              'Aug',
              'Sep',
              'Oct',
              'Nov',
              'Dec',
            ],
            labels: {
              style: {
                colors: labelColor,
              },
            },
          },
          yaxis: {
            title: {
              text: 'kg CO2',
              style: {
                color: labelColor,
                fontWeight: '500',
                fontSize: '14px',
              },
            },
            labels: {
              style: {
                colors: labelColor,
              },
            },
          },

        }
      })
  }

  return (
    <div className="overflow-hidden sm:rounded-lg bg-white dark:bg-darkbg4 px-4">
      <h1 className='font-bold text-lg text-left px-6'> {title} </h1>
      {chartData && (
        <Chart
          options={chartData.options}
          series={chartData.series}
          type="line"
          height={350}
        />
      )}
    </div>
  )
}

export default ApexColumnChart
