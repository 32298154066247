import React from 'react'

const EmissionDate = ({data, setEditableData}) => (
  <div className="mb-4">
    <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
      Emission Date
    </label>
    <input
      type="date"
      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
      value={data.emissionDate}
      onChange={(e) =>
        setEditableData({
          ...data,
          emissionDate: e.target.value,
        })
      }
      required
    />
  </div>
)

export default EmissionDate
