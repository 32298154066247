// Reference: https://www.youtube.com/watch?v=Z4KgaYlth0k
import React from 'react'
import { useTheme } from '../../../../contexts/ThemeContext'
import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

const MeterChart = ({ Icon, scope, amount, maxAmount, barColor }) => {
  const percentage = (amount * 100) / maxAmount
  const [themeColor, setThemeColor] = useState('')
  const { theme } = useTheme()

  useEffect(() => {
    if (theme === 'dark') {
      setThemeColor('white')
    } else if (theme === 'light') {
      setThemeColor('mainbg')
    }
  }, [theme])

  return (
    <div className="flex-col w-5/6 text-maindarkbg dark:text-mainbg mb-2">
      <span>Scope {scope}</span>
      <div className="flex justify-between mb-1">
        <Icon size={30}/>
        <span className="font-black text-3xl">{percentage.toFixed(1)}%</span>
      </div>
      <div className={`relative w-full h-2 bg-${themeColor} rounded-full`}>
        <div
          className={`${barColor} absolute h-2 rounded-full flex justify-center`}
          style={{ width: `${percentage}%` }}
        ></div>
      </div>
    </div>
  )
}

{
  /* <div className='flex justify-center pt-10 pb-20'>
<MeterChart amount = {100} maxAmount = {100} barColor={"bg-red-500"}/>
</div> */
}

MeterChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      scope: PropTypes.number.isRequired,
      Icon: PropTypes.elementType.isRequired,
      amount: PropTypes.number.isRequired,
      maxAmount: PropTypes.number.isRequired,
      barColor: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

export default MeterChart
