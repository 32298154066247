import { useState } from 'react';
import {
  BusinessTravelCard,
  EmployeeCommutingCard,
  UpstreamTransportationDistributionCard,
  DownstreamTransportationDistributionCard,
  PurchasedGoodsAndServicesCard,
  CapitalGoodsCard,
  UpstreamLeasedAssetsCard,
  DownstreamLeasedAssetsCard,
  SoldProductsTreatmentCard,
  WasteGeneratedCard,
  FranchisesCard,
  InvestmentsCard,
  FuelEnergyActivitiesCard,
  ProcessOfSoldProductsCard,
  UseOfSoldProductsCard
} from './component/Dashboard/ScopeIII';
import { useLocation } from 'react-router-dom';

import GuidelineModalToggleButton from './component/Dashboard/GuidelineModalToggleButton';
import GuidelineModal from './component/Dashboard/GuidelineModal';

const ScopeIII = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const location  = useLocation()
  const { category } = location.state === null ? "" : location.state
  const handleToggleModal = () => setModalOpen(!isModalOpen);

  function cardCategory() {
    const cards = {
      "Purchased Goods & Services": [<PurchasedGoodsAndServicesCard />, <CapitalGoodsCard />],
      "Fuels and Energy Related": [<FuelEnergyActivitiesCard/>],
      "Transportation and Distribution": [<UpstreamTransportationDistributionCard />, <DownstreamTransportationDistributionCard />],
      "Waste Generated": [<WasteGeneratedCard />],
      "Employee Travels": [<BusinessTravelCard />, <EmployeeCommutingCard />],
      "Leased Assets": [<UpstreamLeasedAssetsCard />, <DownstreamLeasedAssetsCard />],
      "Sold Products": [<ProcessOfSoldProductsCard/>, <UseOfSoldProductsCard/>, <SoldProductsTreatmentCard />],
      "Franchises & Investments": [<FranchisesCard />, <InvestmentsCard />]
    }
    if (category === ""){
      return Object.values(cards).flat()
    }
    else{
      return cards[category]
    }
  }

  return (
    <div className="flex flex-col bg-mainbg dark:bg-maindarkbg w-screen max-h-screen overflow-y-scroll">
      <div className="hidden lg:flex pl-10 mt-10">
        <GuidelineModalToggleButton
          onClick={handleToggleModal}
          buttonText="Guidelines"
        />
      </div>

      <div className={`flex flex-col lg:flex-row grid ${category === "" ? "grid-cols-3" : "grid-flow-col auto-cols-max"} gap-y-10 justify-around items-center pt-10 px-1 lg:pl-15 xl:pl-20`}>
        {cardCategory().map((card) => (
          card
        ))}
      </div>

      <GuidelineModal
        isOpen={isModalOpen}
        onClose={handleToggleModal}
        title="Modal Title"
        subtitle="Modal Subtitle"
        content={<p>This is a guideline for scope III</p>}
      />
    </div>
  );
};

export default ScopeIII;
