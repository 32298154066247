import React, { useEffect, useState } from 'react'
import { DateTime } from 'luxon'
import { EmissionDate, ReportingTable } from './component/Table'
import { columnDefinitionEmployeeCommuting } from './component/Table/column'
import { FiPlus, FiTrash } from 'react-icons/fi'
import { useLocation, useNavigate } from 'react-router-dom'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import axios from 'axios'
import * as types from './reportData.json'

const ReportEmployeeCommuting = () => {
  const column = columnDefinitionEmployeeCommuting
  const navigate = useNavigate()
  const location = useLocation()
  const axiosPrivate = useAxiosPrivate()
  const { bypassModal } = location.state === null ? false : location.state
  const {vehicleScope6and7 } = types

  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [showContentModal, setShowContentModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [modalMode, setModalMode] = useState(null) // 'add' or 'edit'
  const [editableData, setEditableData] = useState({
    emissionDate: '',
    reportBy: '',
    employee: '',
    vehicleType: {"public": "", "personal": ""},
    vehicleClass: {"public": "", "personal": ""},
    fuelConsumed: 0,
    distanceTravelled: 0,
    spendAmount: 0,
    fuelCost: 0,
    fuelEfficiency: 0,
    workDays: 0,
    workWeeks: 0,
    employeeAmount: 0,
    totalCommutePercent: 0,
    commuteAmount: {"public": 0, "personal": 0},
    oneWayDistance: {"public": 0, "personal": 0},
    description: '',
  })
  const [selectedRows, setSelectedRows] = useState(new Set())
  const [rowToDelete, setRowToDelete] = useState(null)

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()

    const fetchData = async () => {
      setIsLoading(true)
      try {
        const response = await axiosPrivate.get(
          'employeeCommuting/read-S3G-records',
          {
            signal: controller.signal,
          },
        )
        if (isMounted) {
          // console.log(response.data);
          setData(response.data)
        }
      } catch (err) {
        if (axios.isCancel(err)) {
          // Log for debugging purposes
          // console.log('Request cancelled:', err.message);
        } else {
          console.error('Request failed:', err)
          // Only navigate if the error was not a cancellation
          // Check for a 403 status code specifically
          if (err.response && err.response.status === 403) {
            navigate('/sign-in', { state: { from: location }, replace: true })
          }
        }
      }

      setIsLoading(false)
    }

    fetchData()

    if (bypassModal === true) {
      handleAddNewClick()
    }

    return () => {
      isMounted = false
      controller.abort()
      // console.log('Cleanup: Cancelled any ongoing requests.');
    }
  }, [navigate, location, axiosPrivate])

  const handleAddNewClick = () => {
    setEditableData({
      emissionDate: '',
      reportBy: '',
      employee: '',
      vehicleType: {"public": "", "personal": ""},
      vehicleClass: {"public": "", "personal": ""},
      fuelConsumed: 0,
      distanceTravelled: 0,
      spendAmount: 0,
      fuelCost: 0,
      fuelEfficiency: 0,
      workDays: 0,
      workWeeks: 0,
      employeeAmount: 0,
      totalCommutePercent: 0,
      commuteAmount: {"public": 0, "personal": 0},
      oneWayDistance: {"public": 0, "personal": 0},
      description: '',
    })
    setModalMode('add')
    setShowContentModal(true)
  }

  const handleDeleteClick = () => {
    if (selectedRows.size > 0) {
      setShowDeleteModal(true)
    } else {
      alert('No rows selected') // Or show a more user-friendly message
    }
  }

  const requestRowDeletion = (rowCategory, rowId) => {
    setRowToDelete(rowId)
    setShowDeleteModal(true)
  }

  const confirmDelete = async () => {
    if (rowToDelete !== null) {
      let isMounted = true
      const controller = new AbortController()

      try {
        await axiosPrivate.delete(
          `/employeeCommuting/delete-S3G-record/${rowToDelete}`,
          {
            headers: { 'Content-Type': 'application/json' },
            signal: controller.signal,
          },
        )

        if (isMounted) {
          // Update the state to reflect the deletion
          setData((prevData) =>
            prevData.filter((row) => row.id !== rowToDelete),
          )
          setRowToDelete(null)
        }
      } catch (err) {
        if (!axios.isCancel(err)) {
          console.error('Deletion failed:', err)
          if (err.response) {
            // Redirect on 403 Forbidden or show an error message
            if (err.response.status === 403) {
              navigate('/sign-in', {
                state: { from: location },
                replace: true,
              })
            } else {
              alert(
                `Error: ${err.response.data.message || 'An error occurred.'}`,
              )
            }
          }
        }
      } finally {
        if (isMounted) {
          setShowDeleteModal(false) // Close the modal upon completion or error
        }
      }

      // Cleanup function to prevent state updates if the component unmounts
      return () => {
        isMounted = false
        controller.abort()
      }
    } else {
      // If rowToDelete is null, just close the modal
      setShowDeleteModal(false)
    }
  }

  const deleteSelectedRows = async () => {
    // Check if any rows are selected for deletion
    if (selectedRows.size > 0) {
      let isMounted = true
      const controller = new AbortController()

      try {
        const recordIdsToDelete = Array.from(selectedRows)

        await axiosPrivate.delete('/employeeCommuting/delete-S3G-records', {
          data: { ids: recordIdsToDelete }, // Sending user IDs in the request body
          headers: { 'Content-Type': 'application/json' },
          signal: controller.signal,
        })

        if (isMounted) {
          // Filter out the deleted users from the current data
          setData((prevData) =>
            prevData.filter((row) => !selectedRows.has(row.id)),
          )
          setSelectedRows(new Set()) // Clear selected rows after successful deletion
        }
      } catch (err) {
        if (!axios.isCancel(err)) {
          console.error('Deletion failed:', err)
          if (err.response) {
            // Handle specific HTTP status codes here
            if (err.response.status === 403) {
              navigate('/sign-in', {
                state: { from: location },
                replace: true,
              })
            } else {
              alert(
                `Error: ${err.response.data.message || 'An error occurred.'}`,
              )
            }
          }
        }
      } finally {
        if (isMounted) {
          setShowDeleteModal(false) // Close the modal upon completion or error
        }
      }

      // Cleanup function to prevent state updates if the component unmounts
      return () => {
        isMounted = false
        controller.abort()
      }
    } else {
      // If no rows were selected, just close the modal
      setShowDeleteModal(false)
    }
  }

  // Function to handle edit requests
  const handleEditClick = (rowData) => {
    setEditableData(rowData)
    setModalMode('edit')
    setShowContentModal(true)
  }

  const handleAddNewSubmit = async (event) => {
    event.preventDefault()

    const endpoint =
      modalMode === 'edit'
        ? `/employeeCommuting/edit-S3G-record/${editableData.id}`
        : '/employeeCommuting/create-S3G-record'
    const method = modalMode === 'edit' ? 'patch' : 'post'

    // Prepare the data payload
    const payload = {
      scope: 3,
      category: `employeeCommuting`,
      emissionDate: editableData.emissionDate,
      reportBy: editableData.reportBy,
      employee: editableData.employee,
      vehicleType: editableData.vehicleType,
      vehicleClass: editableData.vehicleClass,
      fuelConsumed: editableData.fuelConsumed,
      distanceTravelled: editableData.distanceTravelled,
      spendAmount: editableData.spendAmount,
      fuelCost: editableData.fuelCost,
      fuelEfficiency: editableData.fuelEfficiency,
      workDays: editableData.workDays,
      workWeeks: editableData.workWeeks,
      employeeAmount: editableData.employeeAmount,
      totalCommutePercent: editableData.totalCommutePercent,
      commuteAmount: editableData.commuteAmount,
      oneWayDistance: editableData.oneWayDistance,
      description: editableData.description,
    }

    try {
      const response = await axiosPrivate[method](endpoint, payload, {
        headers: { 'Content-Type': 'application/json' },
      })

      // Update the state based on the modal mode
      if (modalMode === 'edit') {
        setData((currentData) =>
          currentData.map((item) =>
            item.id === editableData.id ? { ...item, ...response.data } : item,
          ),
        )
      } else {
        setData((currentData) => [...currentData, response.data])
      }

      // Reset form and close modal
      setEditableData({
        emissionDate: '',
        reportBy: '',
        employee: '',
        vehicleType: {"public": "", "personal": ""},
        vehicleClass: {"public": "", "personal": ""},
        fuelConsumed: 0,
        distanceTravelled: 0,
        spendAmount: 0,
        fuelCost: 0,
        fuelEfficiency: 0,
        workDays: 0,
        workWeeks: 0,
        employeeAmount: 0,
        totalCommutePercent: 0,
        commuteAmount: {"public": 0, "personal": 0},
        oneWayDistance: {"public": 0, "personal": 0},
        description: '',
      })
      setShowContentModal(false)
    } catch (err) {
      if (!axios.isCancel(err)) {
        console.error('Submission failed:', err)
        const errorMsg = err.response?.data.message || 'An error occurred.'
        if (err.response?.status === 403) {
          navigate('/sign-in', { state: { from: location }, replace: true })
        } else {
          alert(`Error: ${errorMsg}`)
        }
      }
    }
  }
  
  function handleTypeChange(e, key) {
    const {name, value} = e.target
    let copyOfData = {...editableData}
    copyOfData[name][key] = value
    setEditableData(copyOfData)    
  }

  if (isLoading) {
    return <div>Loading...</div> // Or any loading spinner
  }

  return (
    <div className="flex flex-col bg-mainbg dark:bg-maindarkbg text-black dark:text-white p-4 md:p-10 w-full overflow-y-auto h-full">
      <div className="flex flex-col md:flex-row justify-between items-center mb-4">
        <h2 className="text-xl md:text-2xl font-bold mb-4 md:mb-0 dark:text-white">
          Employee Commuting
        </h2>
        <div className="flex space-x-2">
          <button
            onClick={handleAddNewClick}
            className="flex items-center justify-center bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700 dark:bg-blue-600 dark:hover:bg-blue-800 transition duration-300 w-40"
            aria-label="Add new item"
          >
            <FiPlus className="mr-2" />
            <span className="flex-1 text-center">Add</span>
          </button>
          <button
            onClick={handleDeleteClick}
            className="flex items-center justify-center bg-red-500 text-white py-2 px-4 rounded hover:bg-red-700 dark:bg-red-600 dark:hover:bg-red-800 transition duration-300 w-40"
            aria-label="Delete selected items"
          >
            <FiTrash className="mr-2" />
            <span className="flex-1 text-center">Delete</span>
          </button>
        </div>
      </div>
      {/* {showContentModal && <MyModal onClose={() => setShowContentModal(false)} />} */}
      <ReportingTable
        data={data}
        columns={column}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        onRequestDelete={requestRowDeletion}
        onEdit={handleEditClick}
      />

      {showDeleteModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-md flex justify-center items-center p-4">
          <div className="bg-white dark:bg-gray-800 w-full max-w-md mx-auto rounded shadow-lg overflow-hidden p-4">
            <p className="text-gray-800 dark:text-gray-200 text-center text-lg md:text-base">
              Are you sure you want to delete the selected items?
            </p>
            <div className="flex justify-end mt-4">
              <button
                onClick={() => setShowDeleteModal(false)}
                className="bg-gray-300 dark:bg-gray-600 dark:text-gray-200 px-4 py-2 rounded mr-2 text-sm md:text-base hover:bg-gray-400 dark:hover:bg-gray-700 transition duration-300"
              >
                Cancel
              </button>
              <button
                onClick={() =>
                  rowToDelete !== null ? confirmDelete() : deleteSelectedRows()
                }
                className="bg-red-500 text-white px-4 py-2 rounded text-sm md:text-base hover:bg-red-600 transition duration-300"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}

      {showContentModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-md flex justify-center items-center p-4">
          <div className="bg-white dark:bg-gray-800 w-full max-w-lg mx-auto rounded shadow-lg overflow-hidden p-6 max-h-[90vh] overflow-y-auto">
            <h2 className="text-lg font-semibold mb-4">
              {modalMode === 'edit' ? 'Edit Data' : 'Add New Data'}
            </h2>
            <form onSubmit={handleAddNewSubmit}>
              {/* Emission Date Input */}
              <EmissionDate data={editableData} setEditableData = {setEditableData}/>

              {/* ReportBy Dropdown */}
              <div className="mb-4">
                <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                  Report By
                </label>
                <select
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                  value={editableData.reportBy}
                  onChange={(e) =>
                    setEditableData({
                      ...editableData,
                      reportBy: e.target.value,
                      employee: '',
                      vehicleType: {"public": "", "personal": ""},
                      vehicleClass: {"public": "", "personal": ""},
                      fuelConsumed: 0,
                      distanceTravelled: 0,
                      spendAmount: 0,
                      fuelCost: 0,
                      fuelEfficiency: 0,
                      workDays: 0,
                      workWeeks: 0,
                      employeeAmount: 0,
                      totalCommutePercent: 0,
                      commuteAmount: {"public": 0, "personal": 0},
                      oneWayDistance: {"public": 0, "personal": 0},
                      description: '',
                    })
                  }
                  required
                >
                  <option value="">Select Type</option>
                  <option value="Fuel-Based Method">
                    Fuel-Based Method
                  </option>
                  <option value="Distance-Based Method">
                    Distance-Based Method
                  </option>
                  <option value="Average-Data Method">
                    Average-Data Method
                  </option>
                </select>
              </div>

              {(editableData.reportBy === 'Fuel-Based Method' || editableData.reportBy === 'Distance-Based Method') && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Employee
                  </label>
                  <input
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.employee}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        employee: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              )}

              {editableData.reportBy === 'Average-Data Method' && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Total Employees
                  </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.employeeAmount}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        employeeAmount: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              )}

              {(editableData.reportBy === 'Fuel-Based Method' || editableData.reportBy === "Average-Data Method") && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    {editableData.reportBy === "Average-Data Method" ? "Average Work Days" : "Work Days"}
                  </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.workDays}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        workDays: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              )}

              {editableData.reportBy === "Distance-Based Method" && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Public Transport Commute (times per week)
                  </label>
                  <input
                    type="number"
                    name="commuteAmount"
                    onWheel={(e) => e.target.blur()}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.commuteAmount["public"]}
                    onChange={(e) => handleTypeChange(e, "public")
                    }
                    required
                  />
                </div>
              )}

              {editableData.reportBy === "Distance-Based Method" && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    One Way Distance by Public Transport (km)
                  </label>
                  <input
                    type="number"
                    name="oneWayDistance"
                    onWheel={(e) => e.target.blur()}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.oneWayDistance["public"]}
                    onChange={(e) => handleTypeChange(e, "public")
                    }
                    required
                  />
                </div>
              )}

              {editableData.reportBy && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Vehicle Type
                  </label>
                  <select
                    name = "vehicleType"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.vehicleType["public"]}
                    onChange={(e) => handleTypeChange(e, "public")}
                    required
                  >
                    <option value="">Select Type</option>
                    {Object.keys(vehicleScope6and7).map((mode) => (
                      <option key={mode} value={mode}>
                        {mode}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              {editableData.reportBy && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Vehicle Class
                  </label>
                  <select
                    name = "vehicleClass"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.vehicleClass["public"]}
                    onChange={(e) => handleTypeChange(e, "public")}
                    required
                  >
                    <option value="">Select Type</option>
                    {editableData.vehicleType["public"] && Object.keys(vehicleScope6and7[editableData.vehicleType["public"]]).map((mode) => (
                      <option key={mode} value={mode}>
                        {mode}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              {editableData.reportBy === "Distance-Based Method" && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Personal Vehicle Commute (times per week)
                  </label>
                  <input
                    type="number"
                    name="commuteAmount"
                    onWheel={(e) => e.target.blur()}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.commuteAmount["personal"]}
                    onChange={(e) => handleTypeChange(e, "personal")
                    }
                    required
                  />
                </div>
              )}

              {editableData.reportBy === "Distance-Based Method" && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    One Way Distance by Personal Vehicle (km)
                  </label>
                  <input
                    type="number"
                    name="oneWayDistance"
                    onWheel={(e) => e.target.blur()}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.oneWayDistance["personal"]}
                    onChange={(e) => handleTypeChange(e, "personal")
                    }
                    required
                  />
                </div>
              )}

              {editableData.reportBy === "Distance-Based Method" && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Vehicle Type
                  </label>
                  <select
                    name = "vehicleType"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.vehicleType["personal"]}
                    onChange={(e) => handleTypeChange(e, "personal")}
                    required
                  >
                    <option value="">Select Type</option>
                    {Object.keys(vehicleScope6and7).map((mode) => (
                      <option key={mode} value={mode}>
                        {mode}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              {editableData.reportBy === "Distance-Based Method" && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Vehicle Class
                  </label>
                  <select
                    name = "vehicleClass"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.vehicleClass["personal"]}
                    onChange={(e) => handleTypeChange(e, "personal")}
                    required
                  >
                    <option value="">Select Type</option>
                    {editableData.vehicleType["personal"] && Object.keys(vehicleScope6and7[editableData.vehicleType["personal"]]).map((mode) => (
                      <option key={mode} value={mode}>
                        {mode}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              {editableData.reportBy === 'Distance-Based Method' && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Number of Work Weeks
                  </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.workWeeks}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        workWeeks: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              )}

              {editableData.reportBy === 'Fuel-Based Method' && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Amount (Litre)
                  </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.fuelConsumed}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        fuelConsumed: e.target.value,
                      })
                    }
                  />
                </div>
              )}

              {editableData.reportBy === 'Fuel-Based Method' && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Spend Amount (RM)
                  </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.spendAmount}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        spendAmount: e.target.value,
                      })
                    }
                  />
                </div>
              )}

              {editableData.reportBy === 'Fuel-Based Method' && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Distance Travelled
                  </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.distanceTravelled}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        distanceTravelled: e.target.value,
                      })
                    }
                  />
                </div>
              )}

              {editableData.reportBy === 'Fuel-Based Method' && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Fuel Cost (RM/litre)
                  </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.fuelCost}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        fuelCost: e.target.value,
                      })
                    }
                  />
                </div>
              )}

              {editableData.reportBy === 'Fuel-Based Method' && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Fuel Efficiency (litre/km)
                  </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.fuelEfficiency}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        fuelEfficiency: e.target.value,
                      })
                    }
                  />
                </div>
              )}

              {editableData.reportBy === 'Average-Data Method' && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Percent of Total Commutes (%)
                  </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.totalCommutePercent}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        totalCommutePercent: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              )}

              {editableData.reportBy === "Average-Data Method" && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Average One-Way Distance (km)
                  </label>
                  <input
                    type="number"
                    name="oneWayDistance"
                    onWheel={(e) => e.target.blur()}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.oneWayDistance["public"]}
                    onChange={(e) => handleTypeChange(e, "public")
                    }
                    required
                  />
                </div>
              )}

              {/* Description Input */}
              <div className="mb-4">
                <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                  Description
                </label>
                <textarea
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                  value={editableData.description}
                  onChange={(e) =>
                    setEditableData({
                      ...editableData,
                      description: e.target.value,
                    })
                  }
                  required
                />
              </div>

              <div className="flex justify-end mt-4">
                <button
                  type="button"
                  onClick={() => setShowContentModal(false)}
                  className="bg-gray-300 dark:bg-gray-600 dark:text-gray-200 px-4 py-2 rounded mr-2"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-blue-500 text-white px-4 py-2 rounded"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  )

}

export default ReportEmployeeCommuting
