// Reference: https://www.youtube.com/watch?v=yAI9fbbH-rM
// https://apexcharts.com/react-chart-demos/radialbar-charts/gradient/

import { useEffect, useState } from 'react'
import Chart from 'react-apexcharts'
import { useTheme } from '../../../../contexts/ThemeContext'

const ApexGaugeChart = ({ amount, maxAmount, title }) => {
  const percentage = (amount * 100) / maxAmount
  const { theme } = useTheme()
  const [style, setStyle] = useState({
    transform: '',
    transition: 'transform 1000ms linear'
  })
  const [chartData, setChartData] = useState()

  useEffect(() => {
    if (theme === 'dark') {
      renderChart("#d1d5db")
    } else if (theme === 'light') {
      renderChart("#6b7280")
    }
  }, [theme])

  useEffect(() => {
    rotationAngle()
  }, [amount, maxAmount])

  function renderChart(labelColor) {
    setChartData({
        series: [percentage.toFixed(1)],
        options: {
          chart: {
            type: 'radialBar',
            toolbar: {
                enabled: true
            }
          },
          plotOptions: {
            radialBar: {
              startAngle: -135,
              endAngle: 135,
              hollow: {
                margin: 0,
                size:'60%'
              },
              track: {
                background: "#e7e7e7",
                strokeWidth: '97%',
                margin: 4, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: 2,
                  left: 0,
                  color: '#444',
                  opacity: 1,
                  blur: 2
                }
              },
              dataLabels: {
                name: {
                    show: false,
                    offsetY: -10,
                    fontSize: "13px"
                    },
                value: {
                    fontSize: "20px",
                    offsetY: 60,
                    fontWeight:'bold', 
                    }
              }
            }
          },
          fill: {
            colors: '#3C30C4',
            type: 'gradient',
            gradient: {
              shade: 'light',
              shadeIntensity: 0.4,
              gradientToColors: ['#E73F76'],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100]
            },
          },
          stroke: {
            lineCap: 'round'
          },
          labels: ["Percentage"]
        },
      
      
    });
  }

  function rotationAngle() {  
    let newAngle = 0
    const angleReset = Math.ceil(45/270 * 100) 
    if (percentage < angleReset) {
      newAngle = Math.round(45 - percentage * Math.ceil(45 / angleReset))
      setStyle({...style, transform: `rotate(${-newAngle}deg`})
    }
    else {
      let newPercentage = percentage - angleReset
      newAngle = Math.round(newPercentage/(100 - angleReset) * 225)
      setStyle({...style, transform: `rotate(${newAngle}deg)`})
    }
  }

  return (
    <div className="relative flex flex-col items-center justify-center">
      {/* <span className="font-semibold text-lg">{title}</span> */}
      <div className="absolute ring-gray-600 mt-4 rounded-full w-28 h-28 overflow-hidden flex items-center justify-center">
        
        <div className = "absolute w-2 h-2 mb-1 bg-mainbg rounded-full"></div>
        {/* <div className = {rotationAngle()}></div> */}
        <div className = {`absolute w-10 h-1 rounded-full mr-10 mb-1 origin-right justify-end items-center flex bg-[#39056C] dark:bg-[#E73F76]`} style = {style}></div>
      </div>
      {chartData && (
        <Chart
          options={chartData.options}
          series={chartData.series}
          type="radialBar"
          height={200}
        />
      )}
    </div>
  )
}


export default ApexGaugeChart
