import React from 'react';
import { MdCardTravel } from 'react-icons/md';
import { BiBuildingHouse } from 'react-icons/bi';
import { ReportingCard } from './ReportingCard';

// Business Travel Card
export const BusinessTravelCard = () => (
  <ReportingCard
    icon={<MdCardTravel />}
    title="Business Travel"
    description="Emissions from employee travel for business purposes, including various transportation modes that are not owned or controlled by the organization."
    link="/scope-iii/business-travel"
    bgColor="bg-blue-600 dark:bg-blue-800"
    textColor="text-white"
  />
);

// Employee Commuting Card
export const EmployeeCommutingCard = () => (
  <ReportingCard
    icon={<BiBuildingHouse />}
    title="Employee Commuting"
    description="Emissions from the employee transportation between worksites and home, including various transportation modes that are not owned or operated by the organization."
    link="/scope-iii/employee-commuting"
    bgColor="bg-pink-600 dark:bg-pink-800"
    textColor="text-white"
  />
);

// Transportation and Distribution
export const UpstreamTransportationDistributionCard = () => (
  <ReportingCard
    icon={<BiBuildingHouse />}
    title="Upstream Transportation and Distribution"
    description="Description"
    link="/scope-iii/upstream-transportation-distribution"
    bgColor="bg-blue-600 dark:bg-blue-800"
    textColor="text-white"
  />
);

// Transportation and Distribution
export const DownstreamTransportationDistributionCard = () => (
  <ReportingCard
    icon={<BiBuildingHouse />}
    title="Downstream Transportation and Distribution"
    description="Description"
    link="/scope-iii/downstream-transportation-distribution"
    bgColor="bg-teal-600 dark:bg-teal-800"
    textColor="text-white"
  />
);

// Purchased Goods and Services
export const PurchasedGoodsAndServicesCard = () => (
  <ReportingCard
    icon={<BiBuildingHouse />}
    title="Purchased Goods and Services"
    description="Description"
    link="/scope-iii/purchased-goods-services"
    bgColor="bg-violet-600 dark:bg-violet-800"
    textColor="text-white"
  />
);

// Capital Goods
export const CapitalGoodsCard = () => (
  <ReportingCard
    icon={<BiBuildingHouse />}
    title="Capital Goods"
    description="Description"
    link="/scope-iii/capital-goods"
    bgColor="bg-sky-600 dark:bg-sky-800"
    textColor="text-white"
  />
);

// Leased Assets
export const UpstreamLeasedAssetsCard = () => (
  <ReportingCard
    icon={<BiBuildingHouse />}
    title="Upstream Leased Assets"
    description="Description"
    link="/scope-iii/upstream-leased-assets"
    bgColor="bg-teal-600 dark:bg-teal-800"
    textColor="text-white"
  />
);

// Leased Assets
export const DownstreamLeasedAssetsCard = () => (
  <ReportingCard
    icon={<BiBuildingHouse />}
    title="Downstream Leased Assets"
    description="Description"
    link="/scope-iii/downstream-leased-assets"
    bgColor="bg-green-600 dark:bg-green-800"
    textColor="text-white"
  />
);

// End of Life Treatment in Sold Products
export const SoldProductsTreatmentCard = () => (
  <ReportingCard
    icon={<BiBuildingHouse />}
    title="End of Life Treatment of Sold Products"
    description="Description"
    link="/scope-iii/sold-products-treatment"
    bgColor="bg-orange-600 dark:bg-orange-800"
    textColor="text-white"
  />
);

// Waste Generated
export const WasteGeneratedCard = () => (
  <ReportingCard
    icon={<BiBuildingHouse />}
    title="Waste Generated in Operation"
    description="Description"
    link="/scope-iii/waste-generated"
    bgColor="bg-red-600 dark:bg-red-800"
    textColor="text-white"
  />
);

// Franchises
export const FranchisesCard = () => (
  <ReportingCard
    icon={<BiBuildingHouse />}
    title="Franchises"
    description="Description"
    link="/scope-iii/franchises"
    bgColor="bg-blue-600 dark:bg-blue-800"
    textColor="text-white"
  />
);

// Investments
export const InvestmentsCard = () => (
  <ReportingCard
    icon={<BiBuildingHouse />}
    title="Investment"
    description="Description"
    link="/scope-iii/investments"
    bgColor="bg-purple-600 dark:bg-purple-800"
    textColor="text-white"
  />
);

// Fuel and Energy Related Activites
export const FuelEnergyActivitiesCard = () => (
  <ReportingCard
    icon={<BiBuildingHouse />}
    title="Fuel and Energy Related Activities"
    description="Description"
    link="/scope-iii/fuel-energy-related-activities"
    bgColor="bg-orange-600 dark:bg-orange-800"
    textColor="text-white"
  />
);

// Process of Sold Products
export const ProcessOfSoldProductsCard = () => (
  <ReportingCard
    icon={<BiBuildingHouse />}
    title="Process of Sold Products"
    description="Description"
    link="/scope-iii/process-of-sold-products"
    bgColor="bg-pink-600 dark:bg-pink-800"
    textColor="text-white"
  />
);

// Use of Sold Products
export const UseOfSoldProductsCard = () => (
  <ReportingCard
    icon={<BiBuildingHouse />}
    title="Use of Sold Products"
    description="Description"
    link="/scope-iii/use-of-sold-products"
    bgColor="bg-sky-600 dark:bg-sky-800"
    textColor="text-white"
  />
);