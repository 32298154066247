import React, { useEffect, useState } from 'react'
import { EmissionDate, ReportingTable } from './component/Table'
import { columnDefinitionUseOfSoldProducts } from './component/Table/column'
import { FiPlus, FiTrash } from 'react-icons/fi'
import { useLocation, useNavigate } from 'react-router-dom'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import axios from 'axios'
import * as types from './reportData.json'

const ReportUseOfSoldProducts = () => {
  const column = columnDefinitionUseOfSoldProducts
  const navigate = useNavigate()
  const location = useLocation()
  const axiosPrivate = useAxiosPrivate()
  const { bypassModal } = location.state === null ? false : location.state
  const { fuelEF, refrigerant, vehicleScope6and7, malaysiaRegion } = types

  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [showContentModal, setShowContentModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [modalMode, setModalMode] = useState(null) // 'add' or 'edit'
  const [editableData, setEditableData] = useState({
    emissionDate: '',
    reportBy: '',
    calculateBy: '',
    product: '',
    totalLifetimeUses: 0,
    productSold: 0,
    fuelType: '',
    fuelUnit: '',
    fuelConsumed: 0,
    electricity: 0,
    region: '',
    refrigerantType: '',
    refrigerantAmount: 0,
    refrigerantPercent: 0,
    ghgType: '',
    ghgAmount: 0,
    annualGHGRelease: 0,
    lifetime: 0,
    allocation: 0,
    totalAllocation: 0,
    scenario: '',
    scenarioUsagePercentage: 0,
    averageUses: 0,
    description: '',
  })
  const [selectedRows, setSelectedRows] = useState(new Set())
  const [rowToDelete, setRowToDelete] = useState(null)

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()

    const fetchData = async () => {
      setIsLoading(true)
      try {
        const response = await axiosPrivate.get(
          'useOfSoldProducts/read-S3K-records',
          {
            signal: controller.signal,
          },
        )

        if (isMounted) {
          // console.log(response.data);
          setData(response.data)
        }
      } catch (err) {
        if (axios.isCancel(err)) {
          // Log for debugging purposes
          // console.log('Request cancelled:', err.message);
        } else {
          console.error('Request failed:', err)
          // Only navigate if the error was not a cancellation
          // Check for a 403 status code specifically
          if (err.response && err.response.status === 403) {
            navigate('/sign-in', { state: { from: location }, replace: true })
          }
        }
      }

      setIsLoading(false)
    }

    fetchData()

    if (bypassModal === true) {
      handleAddNewClick()
    }

    return () => {
      isMounted = false
      controller.abort()
      // console.log('Cleanup: Cancelled any ongoing requests.');
    }
  }, [navigate, location, axiosPrivate])

  const handleAddNewClick = () => {
    setEditableData({
      emissionDate: '',
      reportBy: '',
      calculateBy: '',
      product: '',
      totalLifetimeUses: 0,
      productSold: 0,
      fuelType: '',
      fuelUnit: '',
      fuelConsumed: 0,
      electricity: 0,
      region: '',
      refrigerantType: '',
      refrigerantAmount: 0,
      refrigerantPercent: 0,
      ghgType: '',
      ghgAmount: 0,
      annualGHGRelease: 0,
      lifetime: 0,
      allocation: 0,
      totalAllocation: 0,
      scenario: '',
      scenarioUsagePercentage: 0,
      averageUses: 0,
      description: '',
    })
    setModalMode('add')
    setShowContentModal(true)
  }

  const handleDeleteClick = () => {
    if (selectedRows.size > 0) {
      setShowDeleteModal(true)
    } else {
      alert('No rows selected') // Or show a more user-friendly message
    }
  }

  const requestRowDeletion = (rowCategory, rowId) => {
    setRowToDelete(rowId)
    setShowDeleteModal(true)
  }

  const confirmDelete = async () => {
    if (rowToDelete !== null) {
      let isMounted = true
      const controller = new AbortController()

      try {
        await axiosPrivate.delete(
          `/useOfSoldProducts/delete-S3K-record/${rowToDelete}`,
          {
            headers: { 'Content-Type': 'application/json' },
            signal: controller.signal,
          },
        )

        if (isMounted) {
          // Update the state to reflect the deletion
          setData((prevData) =>
            prevData.filter((row) => row.id !== rowToDelete),
          )
          setRowToDelete(null)
        }
      } catch (err) {
        if (!axios.isCancel(err)) {
          console.error('Deletion failed:', err)
          if (err.response) {
            // Redirect on 403 Forbidden or show an error message
            if (err.response.status === 403) {
              navigate('/sign-in', {
                state: { from: location },
                replace: true,
              })
            } else {
              alert(
                `Error: ${err.response.data.message || 'An error occurred.'}`,
              )
            }
          }
        }
      } finally {
        if (isMounted) {
          setShowDeleteModal(false) // Close the modal upon completion or error
        }
      }

      // Cleanup function to prevent state updates if the component unmounts
      return () => {
        isMounted = false
        controller.abort()
      }
    } else {
      // If rowToDelete is null, just close the modal
      setShowDeleteModal(false)
    }
  }

  const deleteSelectedRows = async () => {
    // Check if any rows are selected for deletion
    if (selectedRows.size > 0) {
      let isMounted = true
      const controller = new AbortController()

      try {
        const recordIdsToDelete = Array.from(selectedRows)

        await axiosPrivate.delete('/useOfSoldProducts/delete-S3K-records', {
          data: { ids: recordIdsToDelete }, // Sending user IDs in the request body
          headers: { 'Content-Type': 'application/json' },
          signal: controller.signal,
        })

        if (isMounted) {
          // Filter out the deleted users from the current data
          setData((prevData) =>
            prevData.filter((row) => !selectedRows.has(row.id)),
          )
          setSelectedRows(new Set()) // Clear selected rows after successful deletion
        }
      } catch (err) {
        if (!axios.isCancel(err)) {
          console.error('Deletion failed:', err)
          if (err.response) {
            // Handle specific HTTP status codes here
            if (err.response.status === 403) {
              navigate('/sign-in', {
                state: { from: location },
                replace: true,
              })
            } else {
              alert(
                `Error: ${err.response.data.message || 'An error occurred.'}`,
              )
            }
          }
        }
      } finally {
        if (isMounted) {
          setShowDeleteModal(false) // Close the modal upon completion or error
        }
      }

      // Cleanup function to prevent state updates if the component unmounts
      return () => {
        isMounted = false
        controller.abort()
      }
    } else {
      // If no rows were selected, just close the modal
      setShowDeleteModal(false)
    }
  }

  // Function to handle edit requests
  const handleEditClick = (rowData) => {
    setEditableData(rowData)
    setModalMode('edit')
    setShowContentModal(true)
  }

  const handleAddNewSubmit = async (event) => {
    event.preventDefault()

    const endpoint =
      modalMode === 'edit'
        ? `/useOfSoldProducts/edit-S3K-record/${editableData.id}`
        : '/useOfSoldProducts/create-S3K-record'
    const method = modalMode === 'edit' ? 'patch' : 'post'

    // Prepare the data payload
    const payload = {
      scope: 3,
      category: `useOfSoldProducts`,
      emissionDate: editableData.emissionDate,
      reportBy: editableData.reportBy,
      calculateBy: editableData.calculateBy,
      product: editableData.product,
      totalLifetimeUses: editableData.totalLifetimeUses,
      productSold: editableData.productSold,
      fuelType: editableData.fuelType,
      fuelUnit: editableData.fuelUnit,
      fuelConsumed: editableData.fuelConsumed,
      electricity: editableData.electricity,
      region: editableData.region,
      refrigerantType: editableData.refrigerantType,
      refrigerantAmount: editableData.refrigerantAmount,
      refrigerantPercent: editableData.refrigerantPercent,
      ghgType: editableData.ghgType,
      ghgAmount: editableData.ghgAmount,
      annualGHGRelease: editableData.annualGHGRelease,
      lifetime: editableData.lifetime,
      allocation: editableData.allocation,
      totalAllocation: editableData.totalAllocation,
      scenario: editableData.scenario,
      scenarioUsagePercentage: editableData.scenarioUsagePercentage,
      averageUses: editableData.averageUses,
      description: editableData.description,
    }

    try {
      const response = await axiosPrivate[method](endpoint, payload, {
        headers: { 'Content-Type': 'application/json' },
      })

      // Update the state based on the modal mode
      if (modalMode === 'edit') {
        setData((currentData) =>
          currentData.map((item) =>
            item.id === editableData.id ? { ...item, ...response.data } : item,
          ),
        )
      } else {
        setData((currentData) => [...currentData, response.data])
      }

      // Reset form and close modal
      setEditableData({
        emissionDate: '',
        reportBy: '',
        calculateBy: '',
        product: '',
        totalLifetimeUses: 0,
        productSold: 0,
        fuelType: '',
        fuelUnit: '',
        fuelConsumed: 0,
        electricity: 0,
        region: '',
        refrigerantType: '',
        refrigerantAmount: 0,
        refrigerantPercent: 0,
        ghgType: '',
        ghgAmount: 0,
        annualGHGRelease: 0,
        lifetime: 0,
        allocation: 0,
        totalAllocation: 0,
        scenario: '',
        scenarioUsagePercentage: 0,
        averageUses: 0,
        description: '',
      })
      setShowContentModal(false)
    } catch (err) {
      if (!axios.isCancel(err)) {
        console.error('Submission failed:', err)
        const errorMsg = err.response?.data.message || 'An error occurred.'
        if (err.response?.status === 403) {
          navigate('/sign-in', { state: { from: location }, replace: true })
        } else {
          alert(`Error: ${errorMsg}`)
        }
      }
    }
  }

  if (isLoading) {
    return <div>Loading...</div> // Or any loading spinner
  }

  return (
    <div className="flex flex-col bg-mainbg dark:bg-maindarkbg text-black dark:text-white p-4 md:p-10 w-full overflow-y-auto h-full">
      <div className="flex flex-col md:flex-row justify-between items-center mb-4">
        <h2 className="text-xl md:text-2xl font-bold mb-4 md:mb-0 dark:text-white">
          Use of Sold Products Reporting
        </h2>
        <div className="flex space-x-2">
          <button
            onClick={handleAddNewClick}
            className="flex items-center justify-center bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700 dark:bg-blue-600 dark:hover:bg-blue-800 transition duration-300 w-40"
            aria-label="Add new item"
          >
            <FiPlus className="mr-2" />
            <span className="flex-1 text-center">Add</span>
          </button>
          <button
            onClick={handleDeleteClick}
            className="flex items-center justify-center bg-red-500 text-white py-2 px-4 rounded hover:bg-red-700 dark:bg-red-600 dark:hover:bg-red-800 transition duration-300 w-40"
            aria-label="Delete selected items"
          >
            <FiTrash className="mr-2" />
            <span className="flex-1 text-center">Delete</span>
          </button>
        </div>
      </div>
      {/* {showContentModal && <MyModal onClose={() => setShowContentModal(false)} />} */}
      <ReportingTable
        data={data}
        columns={column}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        onRequestDelete={requestRowDeletion}
        onEdit={handleEditClick}
      />

      {showDeleteModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-md flex justify-center items-center p-4">
          <div className="bg-white dark:bg-gray-800 w-full max-w-md mx-auto rounded shadow-lg overflow-hidden p-4">
            <p className="text-gray-800 dark:text-gray-200 text-center text-lg md:text-base">
              Are you sure you want to delete the selected items?
            </p>
            <div className="flex justify-end mt-4">
              <button
                onClick={() => setShowDeleteModal(false)}
                className="bg-gray-300 dark:bg-gray-600 dark:text-gray-200 px-4 py-2 rounded mr-2 text-sm md:text-base hover:bg-gray-400 dark:hover:bg-gray-700 transition duration-300"
              >
                Cancel
              </button>
              <button
                onClick={() =>
                  rowToDelete !== null ? confirmDelete() : deleteSelectedRows()
                }
                className="bg-red-500 text-white px-4 py-2 rounded text-sm md:text-base hover:bg-red-600 transition duration-300"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}

      {showContentModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-md flex justify-center items-center p-4">
          <div className="bg-white dark:bg-gray-800 w-full max-w-lg mx-auto rounded shadow-lg overflow-hidden p-6 max-h-[90vh] overflow-y-auto">
            <h2 className="text-lg font-semibold mb-4">
              {modalMode === 'edit' ? 'Edit Data' : 'Add New Data'}
            </h2>
            <form onSubmit={handleAddNewSubmit}>
              {/* Emission Date Input */}
              <EmissionDate data={editableData} setEditableData = {setEditableData}/>

              {/* ReportBy Dropdown */}
              <div className="mb-4">
                <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                  Report By
                </label>
                <select
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                  value={editableData.reportBy}
                  onChange={(e) =>
                    setEditableData({
                      ...editableData,
                      reportBy: e.target.value,
                      calculateBy: '',
                      product: '',
                      totalLifetimeUses: 0,
                      productSold: 0,
                      fuelType: '',
                      fuelUnit: '',
                      fuelConsumed: 0,
                      electricity: 0,
                      region: '',
                      refrigerantType: '',
                      refrigerantAmount: 0,
                      refrigerantPercent: 0,
                      ghgType: '',
                      ghgAmount: 0,
                      annualGHGRelease: 0,
                      lifetime: 0,
                      allocation: 0,
                      totalAllocation: 0,
                      scenario: '',
                      scenarioUsagePercentage: 0,
                    })
                  }
                  required
                >
                  <option value="">Select Type</option>
                  <option value="Direct Use-Phase">Direct Use-Phase</option>
                  <option value="Use-Phase">Use-Phase</option>
                  <option value="Indirect Use-Phase">Indirect Use-Phase</option>
                </select>
              </div>

              {/* CalculateBy Dropdown */}
              {editableData.reportBy === 'Direct Use-Phase' && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Category
                  </label>
                  <select
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.calculateBy}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        calculateBy: e.target.value,
                        product: '',
                        totalLifetimeUses: 0,
                        productSold: 0,
                        fuelType: '',
                        fuelUnit: '',
                        fuelConsumed: 0,
                        electricity: 0,
                        region: '',
                        refrigerantType: '',
                        refrigerantAmount: 0,
                        refrigerantPercent: 0,
                        ghgType: '',
                        ghgAmount: 0,
                        annualGHGRelease: 0,
                        lifetime: 0,
                        allocation: 0,
                        totalAllocation: 0,
                        scenario: '',
                        scenarioUsagePercentage: 0,
                      })
                    }
                    required
                  >
                    <option value="">Select Type</option>
                    <option value="Fuels and Electricity">
                      Fuels and Electricity
                    </option>
                    <option value="Fuels and Feedstocks">
                      Fuels and Feedstocks
                    </option>
                    <option value="Contain GHG, Refrigerants">
                      Contain GHG, Refrigerants
                    </option>
                  </select>
                </div>
              )}

              {/* Product Input*/}
              {((editableData.reportBy === 'Direct Use-Phase' &&
                editableData.calculateBy === 'Fuels and Electricity') ||
                editableData.reportBy === 'Use-Phase' ||
                editableData.reportBy === 'Indirect Use-Phase') && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    {editableData.reportBy === 'Use-Phase'
                      ? 'Intermediate Product'
                      : 'Product'}
                  </label>
                  {editableData.reportBy === 'Use-Phase' ? (
                    <select
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                      value={editableData.product}
                      onChange={(e) =>
                        setEditableData({
                          ...editableData,
                          product: e.target.value,
                        })
                      }
                    >
                      <option value="">Select Type</option>
                      {Object.keys(vehicleScope6and7).map((type) => (
                        <option key={type} value={type}>
                          {type}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <input
                      type="text"
                      onWheel={(e) => e.target.blur()}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                      value={editableData.product}
                      onChange={(e) =>
                        setEditableData({
                          ...editableData,
                          product: e.target.value,
                        })
                      }
                      required
                    />
                  )}
                </div>
              )}

              
              {/* Product Class Input*/}
              {(editableData.reportBy === 'Use-Phase' && editableData.product) && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Intermediate Product Type
                  </label>
                  {editableData.product && 
                  <select
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                      value={editableData.fuelType}
                      onChange={(e) =>
                        setEditableData({
                          ...editableData,
                          fuelType: e.target.value,
                        })
                      }
                    >
                      <option value="">Select Type</option>
                      {editableData.product && Object.keys(vehicleScope6and7[editableData.product]).map((mode) => (
                      <option key={mode} value={mode}>
                        {mode}
                      </option>
                    ))}
                    </select>}
                    
                </div>
              )}

              {/* Products Sold*/}
              {((editableData.reportBy === 'Direct Use-Phase' &&
                (editableData.calculateBy === 'Fuels and Electricity' ||
                  editableData.calculateBy === 'Contain GHG, Refrigerants')) ||
                editableData.reportBy === 'Use-Phase' ||
                editableData.reportBy === 'Indirect Use-Phase') && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    {editableData.reportBy === 'Use-Phase'
                      ? 'Total Intermediate Products Sold'
                      : 'Total Number Products Sold'}
                  </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.productSold}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        productSold: e.target.value,
                      })
                    }
                  />
                </div>
              )}

              {/* Total Lifetime Uses*/}
              {((editableData.reportBy === 'Direct Use-Phase' &&
                editableData.calculateBy === 'Fuels and Electricity') ||
                editableData.reportBy === 'Use-Phase' ||
                editableData.reportBy === 'Indirect Use-Phase') && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    {editableData.reportBy === 'Direct Use-Phase'
                      ? 'Total Uses over Lifetime (times / km)'
                      : null}
                    {editableData.reportBy === 'Use-Phase'
                      ? 'Total Lifetime Uses of Final Sold Product (km)'
                      : null}
                    {editableData.reportBy === 'Indirect Use-Phase'
                      ? 'Lifetime Uses per Product'
                      : null}
                  </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.totalLifetimeUses}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        totalLifetimeUses: e.target.value,
                      })
                    }
                  />
                </div>
              )}

              {/* GHG Uses*/}
              {((editableData.reportBy === 'Direct Use-Phase' &&
                editableData.calculateBy === 'Contain GHG, Refrigerants') ||
                editableData.reportBy === 'Indirect Use-Phase') && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    {editableData.reportBy === 'Direct Use-Phase'
                      ? 'GHG Contained per Product (kg)'
                      : 'GHG Emitted Indirectly (kg)'}
                  </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.ghgAmount}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        ghgAmount: e.target.value,
                      })
                    }
                  />
                </div>
              )}

              {/* Allocation */}
              {editableData.reportBy === 'Use-Phase' && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Allocation (tonnes / m<sup>3</sup>)
                  </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.allocation}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        allocation: e.target.value,
                      })
                    }
                  />
                </div>
              )}

              {/* Total Allocation */}
              {editableData.reportBy === 'Use-Phase' && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Total Allocation (tonnes / m<sup>3</sup>)
                  </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.totalAllocation}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        totalAllocation: e.target.value,
                      })
                    }
                  />
                </div>
              )}

              {/* GHG Type */}
              {((editableData.reportBy === 'Direct Use-Phase' &&
                editableData.calculateBy === 'Contain GHG, Refrigerants') ||
                editableData.reportBy === 'Indirect Use-Phase') && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    GHG Type
                  </label>
                  <select
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.ghgType}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        ghgType: e.target.value,
                      })
                    }
                  >
                    <option value="">Select Type</option>
                    {refrigerant.map((type) => (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              {/* Annual GHG released during use of product (%) */}
              {editableData.reportBy === 'Direct Use-Phase' &&
                editableData.calculateBy === 'Contain GHG, Refrigerants' && (
                  <div className="mb-4">
                    <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                      Annual GHG Released During Use of Product (%)
                    </label>
                    <input
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                      value={editableData.annualGHGRelease}
                      onChange={(e) =>
                        setEditableData({
                          ...editableData,
                          annualGHGRelease: e.target.value,
                        })
                      }
                    />
                  </div>
                )}

              {/* Lifetime */}
              {editableData.reportBy === 'Direct Use-Phase' &&
                editableData.calculateBy === 'Contain GHG, Refrigerants' && (
                  <div className="mb-4">
                    <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                      Lifetime (years)
                    </label>
                    <input
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                      value={editableData.lifetime}
                      onChange={(e) =>
                        setEditableData({
                          ...editableData,
                          lifetime: e.target.value,
                        })
                      }
                    />
                  </div>
                )}

              {/* Scenario */}
              {editableData.reportBy === 'Indirect Use-Phase' && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Scenario
                  </label>
                  <input
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.scenario}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        scenario: e.target.value,
                      })
                    }
                  />
                </div>
              )}

              {/* Scenario Percentage Usage*/}
              {editableData.reportBy === 'Indirect Use-Phase' && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Percentage Usage per Scenario (%)
                  </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.scenarioUsagePercentage}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        scenarioUsagePercentage: e.target.value,
                      })
                    }
                  />
                </div>
              )}

              {/* Fuel Type */}
              {((editableData.reportBy === 'Direct Use-Phase' &&
                (editableData.calculateBy === 'Fuels and Feedstocks' ||
                  editableData.calculateBy === 'Fuels and Electricity')) ||
                editableData.reportBy === 'Indirect Use-Phase') && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    {editableData.calculateBy === 'Fuels and Feedstocks'
                      ? 'Fuel / Feedstock Type'
                      : 'Fuel Type'}
                  </label>
                  {/* In hybrid method, options are needed for fuel*/}
                  {editableData.reportBy === 'Direct Use-Phase' &&
                  editableData.calculateBy === 'Fuels and Feedstocks' ? (
                    <select
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                      value={editableData.fuelType}
                      onChange={(e) =>
                        setEditableData({
                          ...editableData,
                          fuelType: e.target.value,
                        })
                      }
                      required
                    >
                      <option value="">Select Type</option>
                      {/* To adapt to the availability of fuel's emission factor */}
                      {Object.keys(fuelEF).map((type) => (
                        <option key={type} value={type}>
                          {type}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <select
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                      value={editableData.fuelType}
                      onChange={(e) =>
                        setEditableData({
                          ...editableData,
                          fuelType: e.target.value,
                        })
                      }
                    >
                      <option value="">Select Type</option>
                      {/* To adapt to the availability of fuel's emission factor */}
                      {Object.keys(fuelEF).map((type) => (
                        <option key={type} value={type}>
                          {type}
                        </option>
                      ))}
                    </select>
                  )}
                </div>
              )}

              {/* Fuel Unit */}
              {((editableData.reportBy === 'Direct Use-Phase' &&
                (editableData.calculateBy === 'Fuels and Feedstocks' ||
                  editableData.calculateBy === 'Fuels and Electricity')) ||
                editableData.reportBy === 'Indirect Use-Phase') && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Fuel Unit
                  </label>
                  {/* In hybrid method, options are needed for fuel*/}
                  {editableData.reportBy === 'Direct Use-Phase' &&
                  editableData.calculateBy === 'Fuels and Feedstocks' ? (
                    <select
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                      value={editableData.fuelUnit}
                      onChange={(e) =>
                        setEditableData({
                          ...editableData,
                          fuelUnit: e.target.value,
                        })
                      }
                      required
                    >
                      <option value="">Select Type</option>
                      {/* To adapt to the availability of fuel's emission factor */}
                      {editableData.fuelType &&
                        Object.keys(fuelEF[editableData.fuelType]['CO2'])
                          .filter(
                            (key) =>
                              fuelEF[editableData.fuelType]['CO2'][key] !== 0,
                          )
                          .map((unit) => (
                            <option key={unit} value={unit}>
                              {unit}
                            </option>
                          ))}
                    </select>
                  ) : (
                    <select
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                      value={editableData.fuelUnit}
                      onChange={(e) =>
                        setEditableData({
                          ...editableData,
                          fuelUnit: e.target.value,
                        })
                      }
                    >
                      <option value="">Select Type</option>
                      {/* To adapt to the availability of fuel's emission factor */}
                      {editableData.fuelType &&
                        Object.keys(fuelEF[editableData.fuelType]['CO2'])
                          .filter(
                            (key) =>
                              fuelEF[editableData.fuelType]['CO2'][key] !== 0,
                          )
                          .map((unit) => (
                            <option key={unit} value={unit}>
                              {unit}
                            </option>
                          ))}
                    </select>
                  )}
                </div>
              )}

              {/* Fuel Consumed */}
              {((editableData.reportBy === 'Direct Use-Phase' &&
                (editableData.calculateBy === 'Fuels and Feedstocks' ||
                  editableData.calculateBy === 'Fuels and Electricity')) ||
                editableData.reportBy === 'Indirect Use-Phase') && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    {editableData.calculateBy === 'Fuels and Electricity' ||
                    editableData.reportBy === 'Indirect Use-Phase'
                      ? 'Fuel Consumed per Use (litres or km)'
                      : null}
                    {editableData.calculateBy === 'Fuels and Feedstocks'
                      ? 'Fuel / Feedstock Sold Amount'
                      : null}
                  </label>
                  {/* In hybrid method, options are needed for fuel*/}
                  {editableData.reportBy === 'Direct Use-Phase' &&
                  editableData.calculateBy === 'Fuels and Feedstocks' ? (
                    <input
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                      value={editableData.fuelConsumed}
                      onChange={(e) =>
                        setEditableData({
                          ...editableData,
                          fuelConsumed: e.target.value,
                        })
                      }
                      required
                    />
                  ) : (
                    <input
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                      value={editableData.fuelConsumed}
                      onChange={(e) =>
                        setEditableData({
                          ...editableData,
                          fuelConsumed: e.target.value,
                        })
                      }
                    />
                  )}
                </div>
              )}

              {/* Region */}
              {(editableData.calculateBy === 'Fuels and Electricity' ||
                editableData.reportBy === 'Indirect Use-Phase') && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Region
                  </label>
                  {/* In hybrid method, options are needed for fuel*/}
                  <select
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.region}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        region: e.target.value,
                      })
                    }
                  >
                    <option value="">Select Type</option>
                    {malaysiaRegion.map((region) => (
                      <option key={region} value={region}>{region}</option>
                    ))}
                  </select>
                </div>
              )}

              {/* Electricity*/}
              {(editableData.calculateBy === 'Fuels and Electricity' ||
                editableData.reportBy === 'Indirect Use-Phase') && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    {editableData.reportBy === 'Indirect Use-Phase'
                      ? 'Electricity Consumption (kWh)'
                      : 'Electricity Consumed per Use (kWh)'}
                  </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.electricity}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        electricity: e.target.value,
                      })
                    }
                  />
                </div>
              )}

              {/* Refrigerant Type */}
              {(editableData.calculateBy === 'Fuels and Electricity' ||
                editableData.reportBy === 'Indirect Use-Phase') && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Refrigerant
                  </label>
                  <select
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.refrigerantType}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        refrigerantType: e.target.value,
                      })
                    }
                  >
                    <option value="">Select Type</option>
                    {refrigerant.map((type) => (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              {/* Refrigerant Amount */}
              {(editableData.calculateBy === 'Fuels and Electricity' ||
                editableData.reportBy === 'Indirect Use-Phase') && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    {editableData.reportBy === 'Indirect Use-Phase'
                      ? 'Refrigerant Leakage (kg)'
                      : 'Refrigerant Capacity (kg)'}
                  </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.refrigerantAmount}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        refrigerantAmount: e.target.value,
                      })
                    }
                  />
                </div>
              )}

              {/* Refrigerant Leakage Percentage */}
              {editableData.calculateBy === 'Fuels and Electricity' && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Refrigerant Leakage Percentage (%)
                  </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.refrigerantPercent}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        refrigerantPercent: e.target.value,
                      })
                    }
                  />
                </div>
              )}

               {/* Refrigerant Leakage Percentage */}
               {editableData.calculateBy === 'Fuels and Electricity' && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Average Uses per Year (times or km)
                  </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.averageUses}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        averageUses: e.target.value,
                      })
                    }
                  />
                </div>
              )}

              {/* Description Input */}
              <div className="mb-4">
                <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                  Description
                </label>
                <textarea
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                  value={editableData.description}
                  onChange={(e) =>
                    setEditableData({
                      ...editableData,
                      description: e.target.value,
                    })
                  }
                />
              </div>

              <div className="flex justify-end mt-4">
                <button
                  type="button"
                  onClick={() => setShowContentModal(false)}
                  className="bg-gray-300 dark:bg-gray-600 dark:text-gray-200 px-4 py-2 rounded mr-2"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-blue-500 text-white px-4 py-2 rounded"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  )
}

export default ReportUseOfSoldProducts
