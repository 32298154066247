import React, { useEffect, useState } from 'react'
import { EmissionDate, ReportingTable } from './component/Table'
import { columnDefinitionInvestments } from './component/Table/column'
import { FiPlus, FiTrash } from 'react-icons/fi'
import { useLocation, useNavigate } from 'react-router-dom'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import axios from 'axios'
import * as types from './reportData.json'

const ReportInvestments = () => {
  const column = columnDefinitionInvestments
  const navigate = useNavigate()
  const location = useLocation()
  const axiosPrivate = useAxiosPrivate()
  const { bypassModal } = location.state === null ? false : location.state
  const { scope5And12 } = types

  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [showContentModal, setShowContentModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [modalMode, setModalMode] = useState(null) // 'add' or 'edit'
  const [editableData, setEditableData] = useState({
    emissionDate: '',
    reportBy: '',
    calculateBy: '',
    investment: '',
    industry: '',
    investmentType: '',
    investeeCompany: '',
    projectType: '',
    relevantEEIOSector: '',
    projectPhase: '',
    equityShare: 0,
    companyRevenue: 0,
    sectorCompanyRevenue: 0,
    debtInvestmentValue: 0,
    totalProjectCost: 0,
    scope1: 0,
    scope2: 0,
    sectorEF: 0,
    eeioFactor: 0,
    projectCostShare: 0,
    projectRevenue: 0,
    scope12AnnualEmission: 0,
    expectedProjectLifetime: 0,
    description: '',
  })
  const [selectedRows, setSelectedRows] = useState(new Set())
  const [rowToDelete, setRowToDelete] = useState(null)

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()

    const fetchData = async () => {
      setIsLoading(true)
      try {
        const response = await axiosPrivate.get('investments/read-S3O-records', {
          signal: controller.signal,
        })

        if (isMounted) {
          // console.log(response.data);
          setData(response.data)
        }
      } catch (err) {
        if (axios.isCancel(err)) {
          // Log for debugging purposes
          // console.log('Request cancelled:', err.message);
        } else {
          console.error('Request failed:', err)
          // Only navigate if the error was not a cancellation
          // Check for a 403 status code specifically
          if (err.response && err.response.status === 403) {
            navigate('/sign-in', { state: { from: location }, replace: true })
          }
        }
      }

      setIsLoading(false)
    }

    fetchData()

    if (bypassModal === true) {
      handleAddNewClick()
    }

    return () => {
      isMounted = false
      controller.abort()
      // console.log('Cleanup: Cancelled any ongoing requests.');
    }
  }, [navigate, location, axiosPrivate])

  const handleAddNewClick = () => {
    setEditableData({
      emissionDate: '',
      reportBy: '',
      calculateBy: '',
      investment: '',
      industry: '',
      investmentType: '',
      investeeCompany: '',
      projectType: '',
      relevantEEIOSector: '',
      projectPhase: '',
      equityShare: 0,
      companyRevenue: 0,
      sectorCompanyRevenue: 0,
      debtInvestmentValue: 0,
      totalProjectCost: 0,
      scope1: 0,
      scope2: 0,
      sectorEF: 0,
      eeioFactor: 0,
      projectCostShare: 0,
      projectRevenue: 0,
      scope12AnnualEmission: 0,
      expectedProjectLifetime: 0,
      description: '',
    })
    setModalMode('add')
    setShowContentModal(true)
  }

  const handleDeleteClick = () => {
    if (selectedRows.size > 0) {
      setShowDeleteModal(true)
    } else {
      alert('No rows selected') // Or show a more user-friendly message
    }
  }

  const requestRowDeletion = (rowCategory, rowId) => {
    setRowToDelete(rowId)
    setShowDeleteModal(true)
  }

  const confirmDelete = async () => {
    if (rowToDelete !== null) {
      let isMounted = true
      const controller = new AbortController()

      try {
        await axiosPrivate.delete(
          `/investments/delete-S3O-record/${rowToDelete}`,
          {
            headers: { 'Content-Type': 'application/json' },
            signal: controller.signal,
          },
        )

        if (isMounted) {
          // Update the state to reflect the deletion
          setData((prevData) =>
            prevData.filter((row) => row.id !== rowToDelete),
          )
          setRowToDelete(null)
        }
      } catch (err) {
        if (!axios.isCancel(err)) {
          console.error('Deletion failed:', err)
          if (err.response) {
            // Redirect on 403 Forbidden or show an error message
            if (err.response.status === 403) {
              navigate('/sign-in', {
                state: { from: location },
                replace: true,
              })
            } else {
              alert(
                `Error: ${err.response.data.message || 'An error occurred.'}`,
              )
            }
          }
        }
      } finally {
        if (isMounted) {
          setShowDeleteModal(false) // Close the modal upon completion or error
        }
      }

      // Cleanup function to prevent state updates if the component unmounts
      return () => {
        isMounted = false
        controller.abort()
      }
    } else {
      // If rowToDelete is null, just close the modal
      setShowDeleteModal(false)
    }
  }

  const deleteSelectedRows = async () => {
    // Check if any rows are selected for deletion
    if (selectedRows.size > 0) {
      let isMounted = true
      const controller = new AbortController()

      try {
        const recordIdsToDelete = Array.from(selectedRows)

        await axiosPrivate.delete('/investments/delete-S3O-records', {
          data: { ids: recordIdsToDelete }, // Sending user IDs in the request body
          headers: { 'Content-Type': 'application/json' },
          signal: controller.signal,
        })

        if (isMounted) {
          // Filter out the deleted users from the current data
          setData((prevData) =>
            prevData.filter((row) => !selectedRows.has(row.id)),
          )
          setSelectedRows(new Set()) // Clear selected rows after successful deletion
        }
      } catch (err) {
        if (!axios.isCancel(err)) {
          console.error('Deletion failed:', err)
          if (err.response) {
            // Handle specific HTTP status codes here
            if (err.response.status === 403) {
              navigate('/sign-in', {
                state: { from: location },
                replace: true,
              })
            } else {
              alert(
                `Error: ${err.response.data.message || 'An error occurred.'}`,
              )
            }
          }
        }
      } finally {
        if (isMounted) {
          setShowDeleteModal(false) // Close the modal upon completion or error
        }
      }

      // Cleanup function to prevent state updates if the component unmounts
      return () => {
        isMounted = false
        controller.abort()
      }
    } else {
      // If no rows were selected, just close the modal
      setShowDeleteModal(false)
    }
  }

  // Function to handle edit requests
  const handleEditClick = (rowData) => {
    setEditableData(rowData)
    setModalMode('edit')
    setShowContentModal(true)
  }

  const handleAddNewSubmit = async (event) => {
    event.preventDefault()

    const endpoint =
      modalMode === 'edit'
        ? `/investments/edit-S3O-record/${editableData.id}`
        : '/investments/create-S3O-record'
    const method = modalMode === 'edit' ? 'patch' : 'post'

    // Prepare the data payload
    const payload = {
      scope: 3,
      category: `investments`,
      emissionDate: editableData.emissionDate,
      reportBy: editableData.reportBy,
      calculateBy: editableData.calculateBy,
      investment: editableData.investment,
      industry: editableData.industry,
      investmentType: editableData.investmentType,
      investeeCompany: editableData.investeeCompany,
      projectType: editableData.projectType,
      relevantEEIOSector: editableData.relevantEEIOSector,
      projectPhase: editableData.projectPhase,
      equityShare: editableData.equityShare,
      companyRevenue: editableData.companyRevenue,
      sectorCompanyRevenue: editableData.sectorCompanyRevenue,
      debtInvestmentValue: editableData.debtInvestmentValue,
      totalProjectCost: editableData.totalProjectCost,
      scope1: editableData.scope1,
      scope2: editableData.scope2,
      sectorEF: editableData.sectorEF,
      eeioFactor: editableData.eeioFactor,
      projectCostShare: editableData.projectCostShare,
      projectRevenue: editableData.projectRevenue,
      scope12AnnualEmission: editableData.scope12AnnualEmission,
      expectedProjectLifetime: editableData.expectedProjectLifetime,
      description: editableData.description,
    }

    try {
      const response = await axiosPrivate[method](endpoint, payload, {
        headers: { 'Content-Type': 'application/json' },
      })

      // Update the state based on the modal mode
      if (modalMode === 'edit') {
        setData((currentData) =>
          currentData.map((item) =>
            item.id === editableData.id ? { ...item, ...response.data } : item,
          ),
        )
      } else {
        setData((currentData) => [...currentData, response.data])
      }

      // Reset form and close modal
      setEditableData({
        emissionDate: '',
        reportBy: '',
        calculateBy: '',
        investment: '',
        industry: '',
        investmentType: '',
        investeeCompany: '',
        projectType: '',
        relevantEEIOSector: '',
        projectPhase: '',
        equityShare: 0,
        companyRevenue: 0,
        sectorCompanyRevenue: 0,
        debtInvestmentValue: 0,
        totalProjectCost: 0,
        scope1: 0,
        scope2: 0,
        sectorEF: 0,
        eeioFactor: 0,
        projectCostShare: 0,
        projectRevenue: 0,
        scope12AnnualEmission: 0,
        expectedProjectLifetime: 0,
        description: '',
      })
      setShowContentModal(false)
    } catch (err) {
      if (!axios.isCancel(err)) {
        console.error('Submission failed:', err)
        const errorMsg = err.response?.data.message || 'An error occurred.'
        if (err.response?.status === 403) {
          navigate('/sign-in', { state: { from: location }, replace: true })
        } else {
          alert(`Error: ${errorMsg}`)
        }
      }
    }
  }

  function calculateByOption() {
    const options = {
      'Equity Investments': ['Investment-Specific Approach', 'Average-Data Method'],
      'Project Finance & Debt Investments' : ['Project-Specific Approach', 'Average-Data Method', 'Projected Total Lifetime Emissions']
    }

    return options[editableData.reportBy]
  }


  if (isLoading) {
    return <div>Loading...</div> // Or any loading spinner
  }

  return (
    <div className="flex flex-col bg-mainbg dark:bg-maindarkbg text-black dark:text-white p-4 md:p-10 w-full overflow-y-auto h-full">
      <div className="flex flex-col md:flex-row justify-between items-center mb-4">
        <h2 className="text-xl md:text-2xl font-bold mb-4 md:mb-0 dark:text-white">
          Investments
        </h2>
        <div className="flex space-x-2">
          <button
            onClick={handleAddNewClick}
            className="flex items-center justify-center bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700 dark:bg-blue-600 dark:hover:bg-blue-800 transition duration-300 w-40"
            aria-label="Add new item"
          >
            <FiPlus className="mr-2" />
            <span className="flex-1 text-center">Add</span>
          </button>
          <button
            onClick={handleDeleteClick}
            className="flex items-center justify-center bg-red-500 text-white py-2 px-4 rounded hover:bg-red-700 dark:bg-red-600 dark:hover:bg-red-800 transition duration-300 w-40"
            aria-label="Delete selected items"
          >
            <FiTrash className="mr-2" />
            <span className="flex-1 text-center">Delete</span>
          </button>
        </div>
      </div>
      {/* {showContentModal && <MyModal onClose={() => setShowContentModal(false)} />} */}
      <ReportingTable
        data={data}
        columns={column}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        onRequestDelete={requestRowDeletion}
        onEdit={handleEditClick}
      />

      {showDeleteModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-md flex justify-center items-center p-4">
          <div className="bg-white dark:bg-gray-800 w-full max-w-md mx-auto rounded shadow-lg overflow-hidden p-4">
            <p className="text-gray-800 dark:text-gray-200 text-center text-lg md:text-base">
              Are you sure you want to delete the selected items?
            </p>
            <div className="flex justify-end mt-4">
              <button
                onClick={() => setShowDeleteModal(false)}
                className="bg-gray-300 dark:bg-gray-600 dark:text-gray-200 px-4 py-2 rounded mr-2 text-sm md:text-base hover:bg-gray-400 dark:hover:bg-gray-700 transition duration-300"
              >
                Cancel
              </button>
              <button
                onClick={() =>
                  rowToDelete !== null ? confirmDelete() : deleteSelectedRows()
                }
                className="bg-red-500 text-white px-4 py-2 rounded text-sm md:text-base hover:bg-red-600 transition duration-300"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}

      {showContentModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-md flex justify-center items-center p-4">
          <div className="bg-white dark:bg-gray-800 w-full max-w-lg mx-auto rounded shadow-lg overflow-hidden p-6 max-h-[90vh] overflow-y-auto">
            <h2 className="text-lg font-semibold mb-4">
              {modalMode === 'edit' ? 'Edit Data' : 'Add New Data'}
            </h2>
            <form onSubmit={handleAddNewSubmit}>
              {/* Emission Date Input */}
              <EmissionDate data={editableData} setEditableData = {setEditableData}/>
              
              {/* ReportBy Dropdown */}
              <div className="mb-4">
                <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                  Report By
                </label>
                <select
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                  value={editableData.reportBy}
                  onChange={(e) =>
                    setEditableData({
                      ...editableData,
                      reportBy: e.target.value,
                      calculateBy: '',
                      investment: '',
                      industry: '',
                      investmentType: '',
                      investeeCompany: '',
                      projectType: '',
                      relevantEEIOSector: '',
                      projectPhase: '',
                      equityShare: 0,
                      companyRevenue: 0,
                      sectorCompanyRevenue: 0,
                      debtInvestmentValue: 0,
                      totalProjectCost: 0,
                      scope1: 0,
                      scope2: 0,
                      sectorEF: 0,
                      eeioFactor: 0,
                      projectCostShare: 0,
                      projectRevenue: 0,
                      scope12AnnualEmission: 0,
                      expectedProjectLifetime: 0,
                    })
                  }
                  required
                >
                  <option value="">Select Type</option>
                  <option value="Equity Investments">
                    Equity Investments
                  </option>
                  <option value="Project Finance & Debt Investments">
                    Project Finance & Debt Investments
                  </option>
                </select>
              </div>

              {/* CalculateBy Dropdown */}
              {editableData.reportBy&& (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Calculate By
                  </label>
                  <select
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.calculateBy}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        calculateBy: e.target.value,
                        investment: '',
                        industry: '',
                        investmentType: '',
                        investeeCompany: '',
                        projectType: '',
                        relevantEEIOSector: '',
                        projectPhase: '',
                        equityShare: 0,
                        companyRevenue: 0,
                        sectorCompanyRevenue: 0,
                        debtInvestmentValue: 0,
                        totalProjectCost: 0,
                        scope1: 0,
                        scope2: 0,
                        sectorEF: 0,
                        eeioFactor: 0,
                        projectCostShare: 0,
                        projectRevenue: 0,
                        scope12AnnualEmission: 0,
                        expectedProjectLifetime: 0,
                      })
                    }
                    required
                  >
                    <option value="">Select Type</option>
                    {calculateByOption().map((option) => (
                      <option key={option} value={option}>{option}</option>
                    ))}
                  </select>
                </div>
              )}

              {/* Investment Input */}
              {editableData.reportBy === "Equity Investments" && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Investment
                  </label>
                  <input
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.investment}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        investment: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              )}

              {/* Investment Type Input */}
              {editableData.reportBy === "Equity Investments" && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Investment Type
                  </label>
                  <input
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.investmentType}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        investmentType: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              )}

              {/* Investment Type Input */}
              {editableData.reportBy === "Equity Investments" && editableData.calculateBy === "Average-Data Method" && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Industry
                  </label>
                  <input
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.industry}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        industry: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              )}

              {/* Investment Type Input */}
              {(editableData.reportBy === "Project Finance & Debt Investments" && (editableData.calculateBy === "Project-Specific Approach" || editableData.calculateBy === "Average-Data Method")) && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Investee Company
                  </label>
                  <input
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.investeeCompany}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        investeeCompany: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              )}
              
              {/* Project Type Input */}
              {(editableData.reportBy === "Project Finance & Debt Investments" && editableData.calculateBy === "Average-Data Method") && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Project Type
                  </label>
                  <input
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.projectType}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        projectType: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              )}

              {/* Project Phase Input */}
              {(editableData.reportBy === "Project Finance & Debt Investments" && editableData.calculateBy === "Average-Data Method") && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Project Phase
                  </label>
                  <input
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.projectPhase}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        projectPhase: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              )}

              {/* Project Revenue Investment*/}
              {(editableData.reportBy === "Project Finance & Debt Investments" && editableData.calculateBy === 'Average-Data Method') && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                  Project Construction Cost or Project Revenue in Reporting Year ($ million)
                    </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.projectRevenue}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        projectRevenue: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              )}

              {/* Relevant EEIO sector Input */}
              {(editableData.reportBy === "Project Finance & Debt Investments" && editableData.calculateBy === "Average-Data Method") && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Relevant EEIO Sector
                  </label>
                  <input
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.relevantEEIOSector}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        relevantEEIOSector: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              )}

              {/* EEIO emission factor Investment*/}
              {(editableData.reportBy === "Project Finance & Debt Investments" && editableData.calculateBy === 'Average-Data Method') && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                  EEIO Emission Factor (Scope 1 and Scope 2 only) (tonnes CO2e / $ million)
                    </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.eeioFactor}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        eeioFactor: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              )}

              {/* Expected Scope 1 and 2*/}
              {(editableData.reportBy === "Project Finance & Debt Investments" && editableData.calculateBy === "Projected Total Lifetime Emissions") && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Expected Scope 1 and 2 Annual Emissions (tonnes CO2e)
                    </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.scope12AnnualEmission}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        scope12AnnualEmission: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              )}

              {/* Expected lifetime of project*/}
              {(editableData.reportBy === "Project Finance & Debt Investments" && editableData.calculateBy === "Projected Total Lifetime Emissions") && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                  Expected Lifetime of Project (years)
                    </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.expectedProjectLifetime}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        expectedProjectLifetime: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              )}

              {/* Share of total project cost*/}
              {(editableData.reportBy === "Project Finance & Debt Investments" && (editableData.calculateBy === 'Average-Data Method' || editableData.calculateBy === "Projected Total Lifetime Emissions")) && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                   Share of Total Project Costs (%)
                    </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.projectCostShare}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        projectCostShare: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              )}

              {/* Debt Investment*/}
              {(editableData.calculateBy === 'Project-Specific Approach') && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Value of Debt Investment ($)
                    </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.debtInvestmentValue}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        debtInvestmentValue: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              )}

              {/* Total Project Cost*/}
              {(editableData.calculateBy === 'Project-Specific Approach') && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                  Total Project Costs (Total Equity Plus Debt) ($)
                    </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.totalProjectCost}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        totalProjectCost: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              )}

              {/* Scope 1 Input*/}
              {(editableData.calculateBy === 'Investment-Specific Approach' || editableData.calculateBy === 'Project-Specific Approach') && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    {editableData.calculateBy === 'Investment-Specific Approach' ? 
                    "Scope 1 of Investee Company (kg CO2e)" :
                    "Scope 1 (kg CO2e)"
                    }
                    </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.scope1}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        scope1: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              )}
              
              {/* Scope 2 Input*/}
              {(editableData.calculateBy === 'Investment-Specific Approach' || editableData.calculateBy === 'Project-Specific Approach') && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    {editableData.calculateBy === 'Investment-Specific Approach' ? 
                    "Scope 2 of Investee Company (kg CO2e)" :
                    "Scope 2 (kg CO2e)"
                    }
                    </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.scope2}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        scope2: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              )}

              {/* Revenue of Investee Company (RM)*/}
              {(editableData.reportBy === "Equity Investments" && editableData.calculateBy === 'Average-Data Method') && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Revenue of Investee Company (RM)
                    </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.companyRevenue}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        companyRevenue: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              )}

              {/* Equity Share Input*/}
              {(editableData.reportBy === "Equity Investments" && editableData.calculateBy === 'Average-Data Method') && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                  Investee Company's Revenue in Sector (%)
                    </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.sectorCompanyRevenue}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        sectorCompanyRevenue: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              )}

              {/* Equity Share Input*/}
              {(editableData.reportBy === "Equity Investments" && editableData.calculateBy === 'Average-Data Method') && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                  Emission Factor for Investee's Sector (kg CO2e / revenue)
                    </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.sectorEF}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        sectorEF: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              )}

              {/* Equity Share Input*/}
              {(editableData.calculateBy === 'Investment-Specific Approach' || (editableData.reportBy === "Equity Investments" && editableData.calculateBy === 'Average-Data Method')) && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Reporting Company's Share of Equity (%)
                    </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.equityShare}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        equityShare: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              )}
              
              {/* Description Input */}
              <div className="mb-4">
                <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                  Description
                </label>
                <textarea
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                  value={editableData.description}
                  onChange={(e) =>
                    setEditableData({
                      ...editableData,
                      description: e.target.value,
                    })
                  }
                  required
                />
              </div>

              <div className="flex justify-end mt-4">
                <button
                  type="button"
                  onClick={() => setShowContentModal(false)}
                  className="bg-gray-300 dark:bg-gray-600 dark:text-gray-200 px-4 py-2 rounded mr-2"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-blue-500 text-white px-4 py-2 rounded"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  )
}

export default ReportInvestments
