import { DateTime } from 'luxon';

export const columnDefinition = [
  // {
  //   header: 'ID',
  //   accessorKey: 'id',
  //   footer: 'ID',
  // },
  {
    header: 'Vehicle Type',
    accessorKey: 'vehicleType',
    footer: 'Vehicle Type',
  },
  // {
  //   header: 'Source',
  //   accessorKey: 'source',
  //   footer: 'Source',
  // },
  {
    header: 'Reporting Basis',
    accessorKey: 'reportingBasis',
    footer: 'Reporting Basis',
  },
  {
    header: 'Quantity',
    accessorKey: 'quantity',
    footer: 'Quantity',
  },  
  {
    header: 'Unit',
    accessorKey: 'unit',
    footer: 'Unit',
  },
  // {
  //   header: 'Created By',
  //   accessorKey: 'createdBy',
  //   footer: 'Created By',
  // },
  {
    header: 'Date of Emission',
    accessorKey: 'emissionDate',
    footer: 'Date of Emission',
    cell: (info) =>
      DateTime.fromISO(info.getValue()).toLocaleString(DateTime.DATE_MED),
  },
  // {
  //   header: 'Actions',
  //   id: 'actions',
  //   // cell: ({ row }) => <button onClick={() => handleEdit(row.original)}>Edit</button>,
  //   cell: ({ row }) => <button onClick={() => {}}>Edit</button>,
  // },
];

export const columnDefinitionStationaryCombustion = [
  {
    header: 'Fuel Type',
    accessorKey: 'fuel',
    footer: 'Fuel Type',
  },
  {
    header: 'Unit',
    accessorKey: 'unit',
    footer: 'Unit',
  },
  {
    header: 'Quantity',
    accessorKey: 'quantity',
    footer: 'Quantity',
  },  
  {
    header: 'Emission (Tonnes CO2e)',
    accessorKey: 'emission',
    footer: 'Emission (Tonnes CO2e)',
  },
  {
    header: 'Date of Emission',
    accessorKey: 'emissionDate',
    footer: 'Date of Emission',
    cell: (info) =>
      DateTime.fromISO(info.getValue()).toLocaleString(DateTime.DATE_MED),
  },
  // {
  //   header: 'Actions',
  //   id: 'actions',
  //   // cell: ({ row }) => <button onClick={() => handleEdit(row.original)}>Edit</button>,
  //   cell: ({ row }) => <button onClick={() => {}}>Edit</button>,
  // },
];

export const columnDefinitionMobileCombustion = [

  {
    header: 'Vehicle Type',
    accessorKey: 'vehicleType',
    footer: 'Vehicle Type',
  },
  {
    header: 'Report By',
    accessorKey: 'reportBy',
    footer: 'Report By',
  },
  {
    header: 'Quantity',
    accessorKey: 'quantity',
    footer: 'Quantity',
  },  
  {
    header: 'Emission (Tonnes CO2e)',
    accessorKey: 'emission',
    footer: 'Emission (Tonnes CO2e)',
  },
  {
    header: 'Date of Emission',
    accessorKey: 'emissionDate',
    footer: 'Date of Emission',
    cell: (info) =>
      DateTime.fromISO(info.getValue()).toLocaleString(DateTime.DATE_MED),
  },
];

export const columnDefinitionFugitiveEmission = [
  {
    header: 'Report By',
    accessorKey: 'reportBy',
    footer: 'Report By',
  },
  {
    header: 'Refrigerant',
    accessorKey: 'refrigerant',
    footer: 'Refrigerant',
  },  
  {
    header: 'Description',
    accessorKey: 'description',
    footer: 'Description',
  },  
  {
    header: 'Emission (Tonnes CO2e)',
    accessorKey: 'emission',
    footer: 'Emission (Tonnes CO2e)',
  },
  {
    header: 'Date of Emission',
    accessorKey: 'emissionDate',
    footer: 'Date of Emission',
    cell: (info) =>
      DateTime.fromISO(info.getValue()).toLocaleString(DateTime.DATE_MED),
  },
];

export const columnDefinitionProcessOfSoldProducts = [
  {
    header: 'Report By',
    accessorKey: 'reportBy',
    footer: 'Report By',
  },
  {
    header: 'Description',
    accessorKey: 'description',
    footer: 'Description',
  },  
  {
    header: 'Emission (Tonnes CO2e)',
    accessorKey: 'emission',
    footer: 'Emission (Tonnes CO2e)',
  },
  {
    header: 'Date of Emission',
    accessorKey: 'emissionDate',
    footer: 'Date of Emission',
    cell: (info) =>
      DateTime.fromISO(info.getValue()).toLocaleString(DateTime.DATE_MED),
  },
];

export const columnDefinitionUseOfSoldProducts = [
  {
    header: 'Report By',
    accessorKey: 'reportBy',
    footer: 'Report By',
  },
  {
    header: 'Description',
    accessorKey: 'description',
    footer: 'Description',
  },  
  {
    header: 'Emission (Tonnes CO2e)',
    accessorKey: 'emission',
    footer: 'Emission (Tonnes CO2e)',
  },
  {
    header: 'Date of Emission',
    accessorKey: 'emissionDate',
    footer: 'Date of Emission',
    cell: (info) =>
      DateTime.fromISO(info.getValue()).toLocaleString(DateTime.DATE_MED),
  },
];

export const columnDefinitionPurchasedGoods = [
  {
    header: 'Report By',
    accessorKey: 'reportBy',
    footer: 'Report By',
  },
  {
    header: 'Purchased Good or Service',
    accessorKey: 'purchasedGoodOrService',
    footer: 'Purchased Good or Service',
  },  
  {
    header: 'Supplier',
    accessorKey: 'supplier',
    footer: 'Supplier',
  },  
  {
    header: 'Quantity',
    accessorKey: 'quantity',
    footer: 'Quantity',
  },  
  {
    header: 'Emission (Tonnes CO2e)',
    accessorKey: 'emission',
    footer: 'Emission (Tonnes CO2e)',
  },
  {
    header: 'Date of Emission',
    accessorKey: 'emissionDate',
    footer: 'Date of Emission',
    cell: (info) =>
      DateTime.fromISO(info.getValue()).toLocaleString(DateTime.DATE_MED),
  },
];

export const columnDefinitionCapitalGoods = [
  {
    header: 'Report By',
    accessorKey: 'reportBy',
    footer: 'Report By',
  },
  {
    header: 'Good or Service',
    accessorKey: 'purchasedGoodOrService',
    footer: 'Good or Service',
  },  
  {
    header: 'Supplier',
    accessorKey: 'supplier',
    footer: 'Supplier',
  },  
  {
    header: 'Quantity',
    accessorKey: 'quantity',
    footer: 'Quantity',
  },  
  {
    header: 'Emission (Tonnes CO2e)',
    accessorKey: 'emission',
    footer: 'Emission (Tonnes CO2e)',
  },
  {
    header: 'Date of Emission',
    accessorKey: 'emissionDate',
    footer: 'Date of Emission',
    cell: (info) =>
      DateTime.fromISO(info.getValue()).toLocaleString(DateTime.DATE_MED),
  },
];

export const columnDefinitionLeasedAssets = [
  {
    header: 'Report By',
    accessorKey: 'reportBy',
    footer: 'Report By',
  },
  {
    header: 'Asset Type',
    accessorKey: 'assetType',
    footer: 'Asset Type',
  },  
  {
    header: 'Emission (Tonnes CO2e)',
    accessorKey: 'emission',
    footer: 'Emission (Tonnes CO2e)',
  },
  {
    header: 'Description',
    accessorKey: 'description',
    footer: 'Description',
  },
  {
    header: 'Date of Emission',
    accessorKey: 'emissionDate',
    footer: 'Date of Emission',
    cell: (info) =>
      DateTime.fromISO(info.getValue()).toLocaleString(DateTime.DATE_MED),
  },
];

export const columnDefinitionTransportationDistribution = [
  {
    header: 'Emission Type',
    accessorKey: `emissionType`,
    footer: 'Emission Typye',
  },
  {
    header: 'Report By',
    accessorKey: 'reportBy',
    footer: 'Report By',
  },
  {
    header: 'Leakage',
    accessorKey: 'leakage',
    footer: 'Leakage',
  },
  {
    header: 'Quantity',
    accessorKey: 'quantity',
    footer: 'Quantity',
  }, 
  {
    header: 'Emission (Tonnes CO2e)',
    accessorKey: 'emission',
    footer: 'Emission (Tonnes CO2e)',
  },
  {
    header: 'Description',
    accessorKey: 'description',
    footer: 'Description',
  },
  {
    header: 'Date of Emission',
    accessorKey: 'emissionDate',
    footer: 'Date of Emission',
    cell: (info) =>
      DateTime.fromISO(info.getValue()).toLocaleString(DateTime.DATE_MED),
  },
];

export const columnDefinitionBusinessTravel = [
  {
    header: 'Report By',
    accessorKey: 'reportBy',
    footer: 'Report By',
  },
  {
    header: 'Vehicle Type',
    accessorKey: 'vehicleType',
    footer: 'Vehicle Type',
  },
  {
    header: 'Quantity',
    accessorKey: 'quantity',
    footer: 'Quantity',
  },
  {
    header: 'Emission (Tonnes CO2e)',
    accessorKey: 'emission',
    footer: 'Emission (Tonnes CO2e)',
  },
  {
    header: 'Date of Emission',
    accessorKey: 'emissionDate',
    footer: 'Date of Emission',
    cell: (info) =>
      DateTime.fromISO(info.getValue()).toLocaleString(DateTime.DATE_MED),
  },
];

export const columnDefinitionEmployeeCommuting = [
  {
    header: 'Report By',
    accessorKey: 'reportBy',
    footer: 'Report By',
  },
  {
    header: 'Employee',
    accessorKey: 'employee',
    footer: 'Employee',
  },
  {
    header: 'Description',
    accessorKey: 'description',
    footer: 'Description',
  },
  {
    header: 'Emission (Tonnes CO2e)',
    accessorKey: 'emission',
    footer: 'Emission (Tonnes CO2e)',
  },
  {
    header: 'Date of Emission',
    accessorKey: 'emissionDate',
    footer: 'Date of Emission',
    cell: (info) =>
      DateTime.fromISO(info.getValue()).toLocaleString(DateTime.DATE_MED),
  },
];

export const columnDefinitionFuelEnergyActivities = [
  {
    header: 'Report By',
    accessorKey: 'reportBy',
    footer: 'Report By',
  },
  {
    header: 'Calculate By',
    accessorKey: 'calculateBy',
    footer: 'Calculate By',
  },
  {
    header: 'Supplier',
    accessorKey: 'supplier',
    footer: 'Supplier',
  },
  {
    header: 'Description',
    accessorKey: 'description',
    footer: 'Description',
  },
  {
    header: 'Emission (Tonnes CO2e)',
    accessorKey: 'emission',
    footer: 'Emission (Tonnes CO2e)',
  },
  {
    header: 'Date of Emission',
    accessorKey: 'emissionDate',
    footer: 'Date of Emission',
    cell: (info) =>
      DateTime.fromISO(info.getValue()).toLocaleString(DateTime.DATE_MED),
  },
];

export const columnDefinitionUsers = [
  {
    header: 'Username',
    accessorKey: 'username',
    footer: 'Username',
  },
  {
    header: 'Full Name',
    accessorKey: 'name',
    footer: 'Full Name',
  },
  {
    header: 'Email',
    accessorKey: 'email',
    footer: 'Email',
  },
  {
    header: 'Gender',
    accessorKey: 'gender',
    footer: 'Gender',
  },
  {
    header: 'User Role',
    accessorKey: 'userRole',
    footer: 'User Role',
  },
];

export const columnDefinitionSoldProductsTreatment = [
  {
    header: 'Report By',
    accessorKey: 'reportBy',
    footer: 'Report By',
  },
  {
    header: 'Mass of Waste',
    accessorKey: 'wasteMass',
    footer: 'Mass of Waste',
  },
  {
    header: 'Waste Treatment',
    accessorKey: 'wasteTreatment',
    footer: 'Waste Treatment',
  },
  {
    header: 'Proportion (%)',
    accessorKey: 'proportion',
    footer: 'Proportion (%)',
  },
  {
    header: 'Emission (Tonnes CO2e)',
    accessorKey: 'emission',
    footer: 'Emission (Tonnes CO2e)',
  },
  {
    header: 'Date of Emission',
    accessorKey: 'emissionDate',
    footer: 'Date of Emission',
    cell: (info) =>
      DateTime.fromISO(info.getValue()).toLocaleString(DateTime.DATE_MED),
  },
];

export const columnDefinitionWasteGenerated = [
  {
    header: 'Report By',
    accessorKey: 'reportBy',
    footer: 'Report By',
  },
  {
    header: 'Waste Disposal Vendor',
    accessorKey: 'disposalVendor',
    footer: 'Waste Disposal Vendor',
  },
  {
    header: 'Waste Treatment',
    accessorKey: 'wasteTreatment',
    footer: 'Waste Treatment',
  },
  {
    header: 'Emission (Tonnes CO2e)',
    accessorKey: 'emission',
    footer: 'Emission (Tonnes CO2e)',
  },
  {
    header: 'Description',
    accessorKey: 'description',
    footer: 'Description',
  },
  {
    header: 'Date of Emission',
    accessorKey: 'emissionDate',
    footer: 'Date of Emission',
    cell: (info) =>
      DateTime.fromISO(info.getValue()).toLocaleString(DateTime.DATE_MED),
  },
];

export const columnDefinitionFranchises = [
  {
    header: 'Report By',
    accessorKey: 'reportBy',
    footer: 'Report By',
  },
  {
    header: 'Franchise',
    accessorKey: 'franchise',
    footer: 'Franchise',
  },
  {
    header: 'Emission (Tonnes CO2e)',
    accessorKey: 'emission',
    footer: 'Emission (Tonnes CO2e)',
  },
  {
    header: 'Description',
    accessorKey: 'description',
    footer: 'Description',
  },
  {
    header: 'Date of Emission',
    accessorKey: 'emissionDate',
    footer: 'Date of Emission',
    cell: (info) =>
      DateTime.fromISO(info.getValue()).toLocaleString(DateTime.DATE_MED),
  },
];

export const columnDefinitionInvestments = [
  {
    header: 'Report By',
    accessorKey: 'reportBy',
    footer: 'Report By',
  },
  {
    header: 'Calculate By',
    accessorKey: 'calculateBy',
    footer: 'Calculate By',
  },
  {
    header: 'Emission (Tonnes CO2e)',
    accessorKey: 'emission',
    footer: 'Emission (Tonnes CO2e)',
  },
  {
    header: 'Description',
    accessorKey: 'description',
    footer: 'Description',
  },
  {
    header: 'Date of Emission',
    accessorKey: 'emissionDate',
    footer: 'Date of Emission',
    cell: (info) =>
      DateTime.fromISO(info.getValue()).toLocaleString(DateTime.DATE_MED),
  },
];

export const columnDefinitionEnergyPurchase = [
  {
    header: 'Report By',
    accessorKey: 'reportBy',
    footer: 'Report By',
  },
  {
    header: 'Comsumption (kWh)',
    accessorKey: 'consumption',
    footer: 'Consumption',
  },
  {
    header: 'Description',
    accessorKey: 'description',
    footer: 'Description',
  },
  {
    header: 'Emission (Tonnes CO2e)',
    accessorKey: 'emission',
    footer: 'Emission (Tonnes CO2e)',
  },
  {
    header: 'Date of Emission',
    accessorKey: 'emissionDate',
    footer: 'Date of Emission',
    cell: (info) =>
      DateTime.fromISO(info.getValue()).toLocaleString(DateTime.DATE_MED),
  },
  // {
  //   header: 'Actions',
  //   id: 'actions',
  //   // cell: ({ row }) => <button onClick={() => handleEdit(row.original)}>Edit</button>,
  //   cell: ({ row }) => <button onClick={() => {}}>Edit</button>,
  // },
];

export const columnDefinitionElectricVehicle = [
  {
    header: 'Vehicle Type',
    accessorKey: 'vehicleType',
    footer: 'Vehicle Type',
  },
  {
    header: 'Region',
    accessorKey: 'region',
    footer: 'Region',
  },
  {
    header: 'Electricity Comsumption (kWh)',
    accessorKey: 'consumption',
    footer: 'Electricity Consumption',
  },
  {
    header: 'Description',
    accessorKey: 'description',
    footer: 'Description',
  },
  {
    header: 'Emission (Tonnes CO2e)',
    accessorKey: 'emission',
    footer: 'Emission (Tonnes CO2e)',
  },
  {
    header: 'Date of Emission',
    accessorKey: 'emissionDate',
    footer: 'Date of Emission',
    cell: (info) =>
      DateTime.fromISO(info.getValue()).toLocaleString(DateTime.DATE_MED),
  },
  // {
  //   header: 'Actions',
  //   id: 'actions',
  //   // cell: ({ row }) => <button onClick={() => handleEdit(row.original)}>Edit</button>,
  //   cell: ({ row }) => <button onClick={() => {}}>Edit</button>,
  // },
];