// Reference: https://www.youtube.com/watch?v=yAI9fbbH-rM
// https://apexcharts.com/react-chart-demos/column-charts/stacked-columns-100/

import { useEffect, useState } from 'react'
import Chart from 'react-apexcharts'
import { useTheme } from '../../../../contexts/ThemeContext'

const ApexPieChart = ({ data, labels, title}) => {
  const { theme } = useTheme()
  const [chartData, setChartData] = useState()

  useEffect(() => {
    if (theme === 'dark') {
      renderChart('#d1d5db')
    } else if (theme === 'light') {
      renderChart('#6b7280')
    }
  }, [theme])

  function renderChart(labelColor) {
    setChartData({
      // series: data ?? [0],
      series: data ?? [10, 20, 30],
      options: {
        labels: [
          `Scope 1 (tonnes CO2e): ${data[0] ?? 0}`,
          `Scope 2 (tonnes CO2e): ${data[1] ?? 0}`,
          `Scope 3 (tonnes CO2e): ${data[2] ?? 0}`,
        ],
        // labels: labels ?? 0,
        legend: {
          position: 'bottom',
          horizontalAlign: 'left',
          floating: false,
          fontSize: '14px',
          fontFamily: 'Inter',
          fontWeight: 400,
          labels: {
            colors: undefined,
            useSeriesColors: true,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 300,
              },
              legend: {
                position: 'bottom',
              },
            },
          },
        ],
      },
    })
  }

  return (
    <div className="overflow-hidden sm:rounded-lg bg-white dark:bg-darkbg4 px-4 py-3 border border-gray-300">
      <h1 className='font-bold text-lg text-left px-6'> {title} </h1>
      {chartData && (
        <Chart
          options={chartData.options}
          series={chartData.series}
          type="pie"
          height={380}
        />
      )}
    </div>
  )
}

export default ApexPieChart
