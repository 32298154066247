import React, { useEffect, useRef, useState } from 'react'
import { FiMenu, FiSettings, FiLogOut, FiCreditCard } from 'react-icons/fi'
import { NavLink, useNavigate } from 'react-router-dom'
import useLogout from '../../../../hooks/useLogout'
import { Link } from 'react-router-dom'
import NavLinks from './NavLinks'
import { FiMoon, FiSun } from 'react-icons/fi'
import { useTheme } from '../../../../contexts/ThemeContext'
import { MdOutlineLiveHelp } from 'react-icons/md'
import { IoIosHelpCircleOutline, IoMdPerson } from 'react-icons/io'

const Navbar = ({ onMenuClick, profileName, profileIcon, disable }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const dropdownRef = useRef(null)
  const navigate = useNavigate()
  const logout = useLogout()
  const { theme, toggleTheme } = useTheme()
  const [open, setOpen] = useState(false)
  const profileMenu = [
    {to: "/userlist", icon: IoMdPerson, name: "Users"},
    {to: "/feedback", icon: MdOutlineLiveHelp, name: "Feedback"},
    {to: "/help", icon: IoIosHelpCircleOutline, name: "Help"},
    // {to: "/subscription", icon: FiSettings, name: "Subscription"},
    {to: "/settings", icon: FiSettings, name: "Settings"},
  ]



  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [dropdownRef])

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen)
  const closeDropdown = () => setDropdownOpen(false)

  // Define the logout function
  const handleLogout = async () => {
    closeDropdown() // Ensure the dropdown is closed upon logout
    // Logout logic goes here (e.g., clear auth token, update state, redirect)
    await logout()
    navigate('/sign-out') // Redirect to sign-in page
  }

  const linkClassName = (baseClasses) =>
    `${baseClasses} ${disable ? 'pointer-events-none opacity-50' : ''}`

  return (
    // <nav className="bg-white">
    <nav className="bg-white dark:bg-slate-700 shadow-md h-14 px-4 lg:px-8 top-0 w-full z-20">
      <div className="flex items-end font-medium justify-between">
        <div className="md:w-auto w-full flex items-center">
          <NavLink
            to="/home"
            className="hidden lg:block text-xl text-blue-900 dark:text-white font-semibold"
            target="_blank"
          >
            <img
              src="/logo.svg"
              alt="Company Logo"
              className="hidden lg:block h-14 mr-2 align-bottom"
            />
          </NavLink>

          {/* <NavLink
            to="/home"
            className="hidden lg:block text-xl text-blue-900 dark:text-white font-semibold"
            target="_blank"
          >
            Tran
            <span className="text-3xl text-black dark:text-amber-100">X</span>
            Energy
          </NavLink> */}

          <div className="text-3xl md:hidden" onClick={() => setOpen(!open)}>
            <ion-icon name={`${open ? 'close' : 'menu'}`}></ion-icon>
          </div>
        </div>
        <ul className="md:flex hidden text-lg items-center gap-8">
          <li>
            <Link
              to="/main"
              className="flex items-center justify-center hover:bg-mainbg dark:text-gray-300 dark:hover:bg-maindarkbg px-3 inline-block h-12 pb-1 text-base rounded-t-md"
            >
              Dashboard
            </Link>
          </li>

          <NavLinks />

          <li>
            <Link
              to="/"
              className="flex items-center justify-center hover:bg-mainbg dark:text-gray-300 dark:hover:bg-maindarkbg px-3 inline-block h-12 pb-1 text-base rounded-t-md"
            >
              Analytics
            </Link>
          </li>

          <li>
            <button
              onClick={toggleTheme}
              className="relative flex items-center justify-center bg-slate-300 dark:bg-gray-600 p-1 rounded-full hover:bg-gray-300 dark:hover:bg-gray-500 transition-colors duration-500 w-12 h-6"
            >
              <span
                className={`absolute ${
                  theme === 'dark' ? 'left-1' : 'right-1'
                } w-4 h-4 rounded-full shadow-md transform transition-transform duration-200`}
              ></span>
              {theme === 'dark' ? (
                <FiMoon
                  className="text-transparent fill-cyan-400 absolute left-1"
                  size={16}
                />
              ) : (
                <FiSun className="text-blue-700 absolute right-1" size={16} />
              )}
            </button>
          </li>

          <li className="relative" ref={dropdownRef}>
            <div
              className="flex items-center cursor-pointer md:block-hidden"
              onClick={toggleDropdown}
            >
              {profileIcon ? (
                <img
                  src={profileIcon}
                  alt="Profile"
                  className="rounded-full border border-gray-300 dark:border-gray-600"
                />
              ) : (
                <div className="flex justify-center items-center w-8 h-8 rounded-full bg-blue-500 text-white">
                  {profileName.charAt(0)}
                </div>
              )}
            </div>
            {dropdownOpen && (
              <div className="absolute right-0 w-48 bg-gray-500 dark:bg-gray-300 mt-3 rounded-md shadow-xl z-20">
                <div className="text-white dark:text-black my-3 text-center text-sm font-normal">
                  {profileName}
                </div>
                {
                  profileMenu.map((row) => (
                    <NavLink
                  to={row.to}
                  className={linkClassName(
                    'block bg-white px-4 py-2 font-medium text-sm text-gray-700 hover:bg-blue-500 hover:text-white',
                  )}
                  onClick={(e) => {
                    disable && e.preventDefault()
                    closeDropdown()
                  }}
                >
                  <row.icon className="inline mr-2" /> {row.name}
                </NavLink>
                  ))
                }

                <NavLink
                  to="/sign-out"
                  className="block bg-white font-medium rounded-b-md px-4 py-2 text-sm text-gray-700 hover:bg-blue-500 hover:text-white"
                  onClick={handleLogout}
                >
                  <FiLogOut className="inline mr-2" /> Sign Out
                </NavLink>
              </div>
            )}
          </li>
        </ul>

        {/* <div className="flex">
          /* Mobile Nav
          <ul
            className={`
        md:hidden bg-white fixed w-full top-0 overflow-y-auto bottom-0 py-24 pl-4
        duration-500 ${open ? 'left-0' : 'left-[-100%]'}
        `}
          >
            <li>
              <Link to="/" className="py-7 px-3 inline-block">
                Home
              </Link>
            </li>
            <NavLinks />
          </ul>
        </div> */}
      </div>
    </nav>
  )
}

export default Navbar
