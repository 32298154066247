import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { SignUp, SignIn, SignOut, Lounge } from './pages/Auth';
import ErrorPage from './pages/Error/ErrorPage';
import AccountHeader from './pages/Auth/component/Auth/AccountHeader';
import HomePage from './pages/HomePage';
import DashboardLayout from './pages/Dashboard/component/Dashboard/DashboardLayout';
import {
  Statistics,
  Feedback,
  ScopeI,
  ScopeII,
  ScopeIII,
  Subscription,
  Settings,
  UsersList,
  Help,
  SiteEmission,
} from './pages/Dashboard';
import {
  ReportBusinessTravel,
  ReportElectricVehicle,
  ReportEmployeeCommuting,
  ReportEnergyPurchase,
  ReportFugitiveEmission,
  ReportMobileCombustion,
  ReportStationaryCombustion,
  ReportUpstreamTransportationDistribution,
  ReportDownstreamTransportationDistribution,
  ReportPurchasedGoods,
  ReportCapitalGoods,
  ReportUpstreamLeasedAssets,
  ReportDownstreamLeasedAssets,
  ReportSoldProductsTreatment,
  ReportWasteGenerated,
  ReportFranchises,
  ReportInvestments,
  ReportFuelEnergyActivities,
  ReportProcessOfSoldProducts,
  ReportUseOfSoldProducts
} from './pages/Table';

import TotalCarbonEmission from './pages/Dashboard/TotalCarbonEmission';
import EmployeeFootprint from './pages/Dashboard/EmployeeFootprint';
import CarbonOffset from './pages/Dashboard/CarbonOffset';
import ChangeInEmission from './pages/Dashboard/ChangeInEmission';
import ForgotPassword from './pages/Auth/ForgotPassword';
import InfoSuccess from './pages/Auth/InfoSuccess';
import ResetPassword from './pages/Auth/ResetPassword';
import SuccessReset from './pages/Auth/SuccessReset';
import CompanyInfo from './pages/Auth/CompanyInfo';
import PersistLogin from './pages/Auth/component/Auth/PersistLogin';

const router = createBrowserRouter([
  {
    path: '/',
    element: <HomePage />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/home',
    element: <HomePage />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/sign-out',
    element: <SignOut />,
    errorElement: <ErrorPage />,
  },
  {
    element: <AccountHeader />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'sign-up',
        element: <SignUp />,
      },
      {
        path: 'sign-in',
        element: <SignIn />,
      },
      {
        path: 'forgot-password',
        element: <ForgotPassword />,
      },
      {
        path: 'info-success',
        element: <InfoSuccess />,
      },
      {
        path: 'reset-password/:token', // Add :token to make the route dynamic
        element: <ResetPassword />,
      },
      {
        path: 'success-reset',
        element: <SuccessReset />,
      },
    ],
  },
  {
    element: <PersistLogin />,
    errorElement: <ErrorPage />,
    children: [
      {
        element: <DashboardLayout />,
        children: [
          {
            path: 'main',
            element: <Statistics />,
          },
          {
            path: 'main/site-emission',
            element: <SiteEmission />,
          },
          {
            path: 'main/total-carbon-emission',
            element: <TotalCarbonEmission />,
          },
          {
            path: 'main/employee-carbon',
            element: <EmployeeFootprint />,
          },
          {
            path: 'main/offset',
            element: <CarbonOffset />,
          },
          {
            path: 'main/change-in-emission',
            element: <ChangeInEmission />,
          },
          {
            path: 'scope-i',
            element: <ScopeI />,
          },
          {
            path: 'scope-i/stationary-combustion',
            element: <ReportStationaryCombustion />,
          },
          {
            path: 'scope-i/mobile-combustion',
            element: <ReportMobileCombustion />,
          },
          {
            path: 'scope-i/fugitive-emission',
            element: <ReportFugitiveEmission />,
          },
          {
            path: 'scope-ii',
            element: <ScopeII />,
          },
          {
            path: 'scope-ii/energy-purchase',
            element: <ReportEnergyPurchase />,
          },
          {
            path: 'scope-ii/electric-vehicle',
            element: <ReportElectricVehicle />,
          },
          {
            path: 'scope-iii',
            element: <ScopeIII />,
          },
          {
            path: 'scope-iii/business-travel',
            element: <ReportBusinessTravel />,
          },
          {
            path: 'scope-iii/employee-commuting',
            element: <ReportEmployeeCommuting />,
          },
          {
            path: 'scope-iii/upstream-transportation-distribution',
            element: <ReportUpstreamTransportationDistribution />,
          },
          {
            path: 'scope-iii/downstream-transportation-distribution',
            element: <ReportDownstreamTransportationDistribution />,
          },
          {
            path: 'scope-iii/purchased-goods-services',
            element: <ReportPurchasedGoods/>,
          },
          {
            path: 'scope-iii/capital-goods',
            element: <ReportCapitalGoods/>,
          },
          {
            path: 'scope-iii/upstream-leased-assets',
            element: <ReportUpstreamLeasedAssets/>,
          },
          {
            path: 'scope-iii/downstream-leased-assets',
            element: <ReportDownstreamLeasedAssets/>,
          },
          {
            path: 'scope-iii/sold-products-treatment',
            element: <ReportSoldProductsTreatment/>,
          },
          {
            path: 'scope-iii/waste-generated',
            element: <ReportWasteGenerated/>,
          },
          {
            path: 'scope-iii/franchises',
            element: <ReportFranchises/>,
          },
          {
            path: 'scope-iii/investments',
            element: <ReportInvestments/>,
          },
          {
            path: 'scope-iii/fuel-energy-related-activities',
            element: <ReportFuelEnergyActivities/>,
          },
          {
            path: 'scope-iii/process-of-sold-products',
            element: <ReportProcessOfSoldProducts/>,
          },
          {
            path: 'scope-iii/use-of-sold-products',
            element: <ReportUseOfSoldProducts/>,
          },
          {
            path: 'feedback',
            element: <Feedback />,
          },
          {
            path: 'settings',
            element: <Settings />,
          },
          {
            path: 'subscription',
            element: <Subscription />,
          },
          {
            path: 'userlist',
            element: <UsersList />,
          },
          {
            path: 'help',
            element: <Help />,
          },
        ],
      },
    ],
  },
]);

function App() {
  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
